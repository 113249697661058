import {
  PaymentsState,
  PaymentsStatePayment,
  PaymentsStateSearchCriteria,
  PaymentsStateSearchStatItem,
} from '@/pages/payments/store/payments-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationPaymentsAddPayments: PaymentsStatePayment[];
    mutationPaymentsSetSearchAfter: string;
    mutationPaymentsSetSearchStats: PaymentsStateSearchStatItem[];
    mutationPaymentsSetSearchCriteria: PaymentsStateSearchCriteria;
    mutationPaymentsClearPayments: never;
  }
}

export function mutationPaymentsAddPayments(
  state: PaymentsState,
  payments: PaymentsStatePayment[],
) {
  state.payments.push(...payments);
}

export function mutationPaymentsSetSearchStats(
  state: PaymentsState,
  statItems: PaymentsStateSearchStatItem[],
) {
  state.searchStats = statItems;
}

export function mutationPaymentsSetSearchAfter(state: PaymentsState, searchAfter: string) {
  state.searchAfter = searchAfter;
}

export function mutationPaymentsSetSearchCriteria(
  state: PaymentsState,
  searchCriteria: PaymentsStateSearchCriteria,
) {
  state.searchCriteria = searchCriteria;
}

export function mutationPaymentsClearPayments(state: PaymentsState) {
  state.payments = [];
  state.searchStats = [];
}
