import { Notification } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/notifications_pb';
import Centrifuge from 'centrifuge/build/protobuf';
import { Subscription } from 'centrifuge/build/subscription';

import { isLocalhost } from '@/helpers/location/location';

export type NotificationHandler = (notification: Notification) => void;

declare global {
  interface Window {
    debugWebsocket: boolean;
  }
}

let centrifuge: Centrifuge | undefined;
let sub: Subscription | null = null;
const userChannelBoundary = '';
const notificationHandlers: NotificationHandler[] = [];

export function connectToWebsocketServer() {
  if (!centrifuge) {
    // centrifuge = new Centrifuge(process.env.VUE_APP_WEBSOCKET_URL as string);
    //
    // // Local centrifugo requires token for user boundary channel.
    // if (isLocalhost()) {
    //   // JWT token for `teamId1`
    //   centrifuge.setToken(
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZWFtSWQxIn0.YA3K8OZSQjgAoxzNL5PaWM0CYSVhTqn8STXTIdLyOoM'
    //   );
    // }
    // centrifuge.connect();

    centrifuge = new Centrifuge(process.env.VUE_APP_WEBSOCKET_URL as string, {
      protocol: 'protobuf',
      token: isLocalhost()
        ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZWFtSWQxIn0.YA3K8OZSQjgAoxzNL5PaWM0CYSVhTqn8STXTIdLyOoM'
        : null,
      // getToken: async () => {
      //   await updateRefreshToken(getRefreshToken());
      //   return getAccessToken();
      // },
    });

    centrifuge.on('disconnected', (error) => {
      console.info('disconnected', error);
    });

    centrifuge.on('error', (error) => {
      console.info('error', error);
    });

    centrifuge.on('leave', (error) => {
      console.info('leave', error);
    });

    centrifuge.on('unsubscribed', (error) => {
      console.info('unsubscribed', error);
    });
  }

  // if (!isWebsocketSubscribed && centrifuge) {
  //   isWebsocketSubscribed = true;
  //
  //   websocketChannelSubscribe();
  // }

  if (centrifuge) {
    // It won't connect if connection is already exists.
    centrifuge.connect();
    websocketChannelSubscribe();
  }

  return centrifuge;
}

// export function setUserChannelBoundary(name: string) {
//   userChannelBoundary = name;
// }

function websocketChannelSubscribe() {
  if (centrifuge) {
    const channelName = 'app:Chatters:Notifications' + userChannelBoundary;
    sub = centrifuge.getSubscription(channelName);

    if (!sub) {
      sub = centrifuge.newSubscription(channelName);
      sub.on('publication', (response) => {
        const notification = Notification.deserializeBinary(response.data);

        if (window.debugWebsocket) {
          console.table(
            Object.fromEntries(
              Object.entries(notification.toObject()).filter(([, value]) => value),
            ),
          );
        }

        notificationHandlers.forEach((handler) => handler(notification));
      });
    }

    // It won't subscribe if subscription is already exists.
    sub.subscribe();
  }
}

// export function websocketChannelResubscribe() {
//   if (sub) {
//     sub.unsubscribe();
//   }
//   websocketChannelSubscribe();
// }

export function addNotificationHandler(handler: NotificationHandler) {
  notificationHandlers.push(handler);
}

export function removeNotificationHandler(handler: NotificationHandler) {
  const index = notificationHandlers.findIndex((h) => h === handler);
  if (index !== -1) {
    notificationHandlers.splice(index, 1);
  }
}
