import {
  GetProfilesRequest,
  GetProfilesResponse,
  MakeDecisionRequest,
  MakeDecisionResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/newprofiles/v1/new_profiles_api_pb';
import { NewProfilesAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/newprofiles/v1/New_profiles_apiServiceClientPb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

export type MakeDecisionResult = MakeDecisionRequest.Result.AsObject;

const newProfilesAPIClient = new NewProfilesAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([newProfilesAPIClient]);

export function getProfiles(limit: number, isAiDeclined: boolean): Promise<GetProfilesResponse> {
  const request = new GetProfilesRequest();
  request.setLimit(limit);
  request.setAiDeclined(isAiDeclined);
  return makeRpcPromisable(newProfilesAPIClient, newProfilesAPIClient.getProfiles, request);
}

export function makeDecision(
  results: MakeDecisionResult[],
  isAiDeclined: boolean,
): Promise<MakeDecisionResponse> {
  const request = new MakeDecisionRequest();
  request.setAiDeclined(isAiDeclined);
  const resultsList: MakeDecisionRequest.Result[] = [];

  results.forEach((res) => {
    const result = new MakeDecisionRequest.Result();
    result.setUserId(res.userId);
    result.setPhotoId(res.photoId);
    result.setOutcome(res.outcome);
    result.setIsTop(res.isTop);

    resultsList.push(result);
  });
  request.setResultsList(resultsList);

  return makeRpcPromisable(newProfilesAPIClient, newProfilesAPIClient.makeDecision, request);
}
