import {
  Assistant,
  Automaton,
  BehaviorPreset,
  DigitalOperator,
  GlobalConfiguration,
  Prompt,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/automation_pb';
import {
  ConfigureAssistantRequest,
  ConfigureRequest,
  ConfigureResponse,
  DeleteBehaviorPresetRequest,
  DeleteBehaviorPresetResponse,
  GenerateResponseRequest,
  GenerateResponseResponse,
  GetAssistantConfigurationRequest,
  GetAssistantConfigurationResponse,
  GetBehaviorPresetRequest,
  GetBehaviorPresetResponse,
  GetConfigurationRequest,
  GetConfigurationResponse,
  ListBehaviorPresetsRequest,
  ListBehaviorPresetsResponse,
  ListDigitalOperatorsRequest,
  ListDigitalOperatorsResponse,
  SaveBehaviorPresetRequest,
  SaveBehaviorPresetResponse,
  SaveDigitalOperatorRequest,
  SaveDigitalOperatorResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_automation_api_pb';
import { ChattersAutomationAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/Chatters_automation_apiServiceClientPb';
import { DialogIdentity } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';
import { Int32Value } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const chattersAutomationAPIClient = new ChattersAutomationAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([chattersAutomationAPIClient]);

export function generateResponse({
  dialogId,
  chatterId,
}: DialogIdentity.AsObject): Promise<GenerateResponseResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new GenerateResponseRequest();
  request.setIdentity(identity);
  request.setAutomaton(Automaton.AUTOMATON_GPT);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.generateResponse,
    request,
  );
}

export function listDigitalOperators(): Promise<ListDigitalOperatorsResponse> {
  const request = new ListDigitalOperatorsRequest();

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.listDigitalOperators,
    request,
  );
}

function convertPromptList(promptList: Prompt.AsObject[]): Prompt[] {
  return promptList.map((promptRaw, i) => {
    const keywordList = new Prompt.Keywords();
    promptRaw.keywords?.keywordsList.forEach((keyword) => {
      keywordList.addKeywords(keyword);
    });

    const depth = new Prompt.Depth();
    depth.setMin(promptRaw.depth?.min ?? 0);
    depth.setMax(promptRaw.depth?.max ?? 0);

    const age = new Prompt.AgeRange();
    age.setFrom(promptRaw.ageRange?.from ?? 0);
    age.setTo(promptRaw.ageRange?.to ?? 0);

    const params = new Prompt.GPTParams();
    params.setTemperature(promptRaw.params?.temperature ?? 0);
    params.setFrequencyPenalty(promptRaw.params?.frequencyPenalty ?? 0);
    params.setPresencePenalty(promptRaw.params?.presencePenalty ?? 0);

    const prompt = new Prompt();
    prompt.setPromptTemplate(promptRaw.promptTemplate);
    prompt.setGender(promptRaw.gender);
    prompt.setKeywords(keywordList);
    prompt.setDepth(depth);
    prompt.setParams(params);
    prompt.setAgeRange(age);
    prompt.setBehaviorPresetId(promptRaw.behaviorPresetId);

    // Do not set mirrorId for the first ( default ) prompt
    if (i !== 0) {
      const mirrorId = new Int32Value();
      mirrorId.setValue(promptRaw.mirrorId?.value ?? 0);
      prompt.setMirrorId(mirrorId);
    }

    const operatorTagList = new Prompt.OperatorTags();
    promptRaw.operatorTags?.tagsList.forEach((tag) => {
      operatorTagList.addTags(tag);
    });
    prompt.setOperatorTags(operatorTagList);

    return prompt;
  });
}

export function saveDigitalOperator(
  botArg: DigitalOperator.AsObject,
): Promise<SaveDigitalOperatorResponse> {
  const request = new SaveDigitalOperatorRequest();

  const bot = new DigitalOperator();
  bot.setId(botArg.id);
  bot.setDisplayName(botArg.displayName);
  bot.setDepth(botArg.depth);
  bot.setStatus(botArg.status);
  bot.setTransactionsPerMinute(botArg.transactionsPerMinute);
  bot.setChannelsList(botArg.channelsList);
  bot.setModel(botArg.model);
  bot.setLocalesList(botArg.localesList);

  const schedule = new DigitalOperator.Schedule();
  schedule.setStartHour(botArg.schedule?.startHour ?? 0);
  schedule.setEndHour(botArg.schedule?.endHour ?? 0);
  bot.setSchedule(schedule);

  bot.setPromptsList(convertPromptList(botArg.promptsList));

  request.setOperator(bot);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.saveDigitalOperator,
    request,
  );
}

export function getConfiguration(): Promise<GetConfigurationResponse> {
  const request = new GetConfigurationRequest();

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.getConfiguration,
    request,
  );
}

export function configure(arg: GlobalConfiguration.AsObject): Promise<ConfigureResponse> {
  const request = new ConfigureRequest();

  const configuration = new GlobalConfiguration();
  configuration.setUserFilterWordsList(arg.userFilterWordsList);
  configuration.setUserStopWordsList(arg.userStopWordsList);
  configuration.setOperatorBlacklistPhrasesList(arg.operatorBlacklistPhrasesList);
  configuration.setOperatorStopWordsList(arg.operatorStopWordsList);

  request.setGlobal(configuration);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.configure,
    request,
  );
}

export function configureAssistant(assistantArg: Assistant.AsObject): Promise<ConfigureResponse> {
  const assistant = new Assistant();
  assistant.setEnabled(assistantArg.enabled);
  assistant.setModel(assistantArg.model);
  assistant.setDepth(assistantArg.depth);
  assistant.setOperatorBlockWordsList(assistantArg.operatorBlockWordsList);
  assistant.setBlackListPhrasesList(assistantArg.blackListPhrasesList);
  assistant.setPromptsList(convertPromptList(assistantArg.promptsList));

  const request = new ConfigureAssistantRequest();
  request.setAssistant(assistant);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.configureAssistant,
    request,
  );
}

export function getAssistantConfiguration(): Promise<GetAssistantConfigurationResponse> {
  const request = new GetAssistantConfigurationRequest();

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.getAssistantConfiguration,
    request,
  );
}

function listBehaviorPresets(
  arg: Required<ListBehaviorPresetsRequest.AsObject>,
): Promise<ListBehaviorPresetsResponse> {
  const request = new ListBehaviorPresetsRequest();
  request.setTitle(arg.title);

  const cursor = new CursorPaginationRequest();
  cursor.setAfterPosition(arg.cursor.afterPosition);
  cursor.setLimit(arg.cursor.limit);
  request.setCursor(cursor);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.listBehaviorPresets,
    request,
  );
}

function getSingleRawListFromSingleLocalList(
  localList: BehaviorPreset.Single.AsObject[],
): BehaviorPreset.Single[] {
  return localList.map((local) => {
    const single = new BehaviorPreset.Single();
    single.setValue(local.value);
    single.setWeight(local.weight);
    return single;
  });
}

function saveBehaviorPreset(preset: BehaviorPreset.AsObject): Promise<SaveBehaviorPresetResponse> {
  const request = new SaveBehaviorPresetRequest();
  const presetRaw = new BehaviorPreset();
  presetRaw.setId(preset.id);
  presetRaw.setTitle(preset.title);

  presetRaw.setNatureList(getSingleRawListFromSingleLocalList(preset.natureList));
  presetRaw.setPersonList(getSingleRawListFromSingleLocalList(preset.personList));
  presetRaw.setLookingForList(getSingleRawListFromSingleLocalList(preset.lookingForList));

  presetRaw.setMoodsList(
    preset.moodsList.map((mood): BehaviorPreset.Mood => {
      const moodRaw = new BehaviorPreset.Mood();
      moodRaw.setMood1(mood.mood1);
      moodRaw.setMood2(mood.mood2);
      moodRaw.setMood3(mood.mood3);
      moodRaw.setWeight(mood.weight);

      return moodRaw;
    }),
  );

  request.setPreset(presetRaw);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.saveBehaviorPreset,
    request,
  );
}

function deleteBehaviorPreset(presetId: string): Promise<DeleteBehaviorPresetResponse> {
  const request = new DeleteBehaviorPresetRequest();
  request.setId(presetId);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.deleteBehaviorPreset,
    request,
  );
}

function getBehaviorPreset(presetId: string): Promise<GetBehaviorPresetResponse> {
  const request = new GetBehaviorPresetRequest();
  request.setId(presetId);

  return makeRpcPromisable(
    chattersAutomationAPIClient,
    chattersAutomationAPIClient.getBehaviorPreset,
    request,
  );
}

export const chattersAutomationApi = {
  listBehaviorPresets,
  saveBehaviorPreset,
  deleteBehaviorPreset,
  getBehaviorPreset,
};
