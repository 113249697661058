import {
  BatchGetUsersPaymentStatsRequest,
  BatchGetUsersPaymentStatsResponse,
  ListReasonsRequest,
  ListReasonsResponse,
  SearchPaymentsRequest,
  SearchPaymentsResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/payments/v1/payments_api_pb';
import { PaymentsAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/payments/v1/Payments_apiServiceClientPb';
import { SearchPaymentsCriteria } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/payments/v1/payments_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { PaymentsStateSearchCriteria } from '@/pages/payments/store/payments-state';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

import OrderDateRange = SearchPaymentsCriteria.OrderDateRange;

const paymentsAPIClient = new PaymentsAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([paymentsAPIClient]);

export function searchPayments({
  criteria,
  limit,
  searchAfter,
}: {
  criteria?: PaymentsStateSearchCriteria;
  limit: number;
  searchAfter: string;
}): Promise<SearchPaymentsResponse> {
  const request = new SearchPaymentsRequest();
  const cursor = new CursorPaginationRequest();
  cursor.setLimit(limit);
  cursor.setAfterPosition(searchAfter);

  if (criteria) {
    const c = new SearchPaymentsCriteria();

    if (criteria.filter) {
      c.setFilter(new StringValue().setValue(criteria.filter));
    }

    if (criteria.status) {
      c.setStatus(criteria.status);
    }

    if (criteria.reasons) {
      const reasons = criteria.reasons.map((reason) => new StringValue().setValue(reason));
      c.setReasonsList(reasons);
    }

    if (criteria.fraudScore) {
      c.setFraudScore(criteria.fraudScore);
    }

    if (criteria.cardCountry) {
      c.setCardCountry(criteria.cardCountry);
    }

    if (criteria.onlyVpn) {
      c.setOnlyVpn(criteria.onlyVpn);
    }

    if (criteria.orderDateRange) {
      const value = new OrderDateRange();
      if (criteria.orderDateRange.fromTimeMs) {
        const from = new Timestamp();
        from.setSeconds(Math.trunc(criteria.orderDateRange.fromTimeMs / 1000));
        value.setFromTime(from);
      }
      if (criteria.orderDateRange.toTimeMs) {
        const to = new Timestamp();
        to.setSeconds(Math.trunc(criteria.orderDateRange.toTimeMs / 1000));
        value.setToTime(to);
      }

      if (value.hasFromTime() || value.hasToTime()) {
        c.setOrderDateRange(value);
      }
    }

    if (criteria.orderDateUpdatedRange) {
      const value = new OrderDateRange();
      if (criteria.orderDateUpdatedRange.fromTimeMs) {
        const from = new Timestamp();
        from.setSeconds(Math.trunc(criteria.orderDateUpdatedRange.fromTimeMs / 1000));
        value.setFromTime(from);
      }
      if (criteria.orderDateUpdatedRange.toTimeMs) {
        const to = new Timestamp();
        to.setSeconds(Math.trunc(criteria.orderDateUpdatedRange.toTimeMs / 1000));
        value.setToTime(to);
      }

      if (value.hasFromTime() || value.hasToTime()) {
        c.setOrderUpdatedDateRange(value);
      }
    }

    request.setCriteria(c);
  }
  request.setCursor(cursor);

  return makeRpcPromisable(paymentsAPIClient, paymentsAPIClient.searchPayments, request);
}

export function listReasons(): Promise<ListReasonsResponse> {
  const request = new ListReasonsRequest();

  return makeRpcPromisable(paymentsAPIClient, paymentsAPIClient.listReasons, request);
}

function batchGetUsersPaymentStats(
  userIdList: string[],
): Promise<BatchGetUsersPaymentStatsResponse> {
  const request = new BatchGetUsersPaymentStatsRequest();
  request.setUserIdsList(userIdList);

  return makeRpcPromisable(paymentsAPIClient, paymentsAPIClient.batchGetUsersPaymentStats, request);
}

export const paymentsApi = {
  batchGetUsersPaymentStats,
};
