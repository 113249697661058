import { ActionContext } from 'vuex';

import {
  getProfilesFrom,
  KEYWORDS_LIST_LIMIT,
} from '@/pages/keywords/store/helpers/keywords-helper';
import {
  KeywordsState,
  KeywordsStateProfile,
  KeywordStateKeyword,
} from '@/pages/keywords/store/keywords-state';
import {
  addKeyword,
  getAllProfiles,
  getProfilesMatches,
  listKeywords,
  resolveProfileMatch,
} from '@/services/api/grpc/keywords/keywords-api';
import { ActionFunction, commit } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionKeywordsGetAllProfiles: ActionFunction<never, KeywordsStateProfile[]>;
    actionKeywordsGetProfilesMatches: ActionFunction<never, KeywordsStateProfile[]>;
    actionKeywordsResolveProfileMatch: ActionFunction<string, void>;
    actionKeywordsGetKeywords: ActionFunction<never, void>;
    actionKeywordsAddKeyword: ActionFunction<string, KeywordStateKeyword[]>;
  }
}

export async function actionKeywordsGetAllProfiles({
  state,
}: ActionContext<KeywordsState, unknown>): Promise<KeywordsStateProfile[]> {
  const searchAfter = state.profilesAll.length > 0 ? state.profilesAllSearchAfter : '';
  const response = await getAllProfiles({
    limit: KEYWORDS_LIST_LIMIT,
    searchAfter,
  });
  const profiles = getProfilesFrom(response);

  commit('mutationKeywordsAddProfilesAll', profiles);
  commit('mutationKeywordsProfilesAllSetSearchAfter', response.getSearchAfter());

  return profiles;
}

export async function actionKeywordsGetProfilesMatches({
  state,
}: ActionContext<KeywordsState, unknown>): Promise<KeywordsStateProfile[]> {
  const searchAfter = state.profilesMatches.length > 0 ? state.profilesMatchesSearchAfter : '';
  const response = await getProfilesMatches({
    limit: KEYWORDS_LIST_LIMIT,
    searchAfter,
  });
  const profiles = getProfilesFrom(response);

  commit('mutationKeywordsAddProfilesMatches', profiles);
  commit('mutationKeywordsProfilesMatchesSetSearchAfter', response.getSearchAfter());

  return profiles;
}

export async function actionKeywordsResolveProfileMatch(
  { state }: ActionContext<KeywordsState, unknown>,
  profileId: string,
): Promise<void> {
  await resolveProfileMatch(profileId);
  commit('mutationKeywordsDeleteProfileMatch', profileId);
}

export async function actionKeywordsGetKeywords({ state }: ActionContext<KeywordsState, unknown>) {
  const response = await listKeywords();

  const keywords = response.getKeywordsList().map((item) => item.toObject());
  commit('mutationKeywordsAddKeywords', keywords);
}

export async function actionKeywordsAddKeyword(
  { state }: ActionContext<KeywordsState, unknown>,
  content: string,
) {
  const response = await addKeyword(content);

  if (response.getIsAlreadyExists()) {
    return response.getAlreadyExistingKeywordsList().map((item) => item.toObject());
  }

  const result = response.getResult()?.toObject() as KeywordStateKeyword;
  commit('mutationKeywordsAddKeywords', [result]);

  return [];
}
