import {
  AddKeywordRequest,
  AddKeywordResponse,
  GetAllProfilesRequest,
  GetAllProfilesResponse,
  GetProfilesMatchesRequest,
  GetProfilesMatchesResponse,
  ListKeywordsRequest,
  ListKeywordsResponse,
  ResolveProfileMatchRequest,
  ResolveProfileMatchResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/keywords_api_pb';
import { KeywordsAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/Keywords_apiServiceClientPb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const keywordsAPIClient = new KeywordsAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([keywordsAPIClient]);

export function getAllProfiles({
  limit,
  searchAfter,
}: GetAllProfilesRequest.AsObject): Promise<GetAllProfilesResponse> {
  const request = new GetAllProfilesRequest();
  request.setLimit(limit);
  request.setSearchAfter(searchAfter);

  return makeRpcPromisable(keywordsAPIClient, keywordsAPIClient.getAllProfiles, request);
}

export function getProfilesMatches({
  limit,
  searchAfter,
}: GetProfilesMatchesRequest.AsObject): Promise<GetProfilesMatchesResponse> {
  const request = new GetProfilesMatchesRequest();
  request.setLimit(limit);
  request.setSearchAfter(searchAfter);

  return makeRpcPromisable(keywordsAPIClient, keywordsAPIClient.getProfilesMatches, request);
}

export function resolveProfileMatch(id: string): Promise<ResolveProfileMatchResponse> {
  const request = new ResolveProfileMatchRequest();
  request.setUserId(id);

  return makeRpcPromisable(keywordsAPIClient, keywordsAPIClient.resolveProfileMatch, request);
}

export function listKeywords(): Promise<ListKeywordsResponse> {
  const request = new ListKeywordsRequest();

  return makeRpcPromisable(keywordsAPIClient, keywordsAPIClient.listKeywords, request);
}

export function addKeyword(content: string): Promise<AddKeywordResponse> {
  const request = new AddKeywordRequest();
  request.setContent(content);

  return makeRpcPromisable(keywordsAPIClient, keywordsAPIClient.addKeyword, request);
}
