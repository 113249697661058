import { UserIdentity } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import { Notification } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/notifications_pb';
import { Status } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';

import {
  hideSessionBreakModal,
  showSessionBreakModal,
} from '@/components/session/session-break-modal/helpers/session-break-modal/session-break-modal';
import SessionInactiveModal from '@/components/session/session-inactive-modal/SessionInactiveModal.vue';
import {
  hideSessionSpinner,
  startSessionKeepAlive,
  stopSessionKeepAlive,
} from '@/hooks/use-session/use-session';
import { getMessageFrom } from '@/pages/chat/store/helpers/chat-helper';
import { getMemberId } from '@/pages/member/store/helpers/member-helper';
import useModal, { hideAllModals } from '@/services/modal/modal';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch } from '@/store/store-helper';

import NotificationCase = Notification.NotificationCase;
import UserCase = UserIdentity.UserCase;

export const chatNotificationHandler: NotificationHandler = (notification) => {
  const notificationCase = notification.getNotificationCase();

  switch (notificationCase) {
    case NotificationCase.DIALOG_MESSAGE: {
      const dialogNotification = notification.getDialogMessage();

      if (dialogNotification) {
        const rawMessage = dialogNotification.getMessage();

        if (rawMessage) {
          const message = getMessageFrom(rawMessage);

          commit('mutationChatAddMessage', {
            dialogId: dialogNotification.getDialogId(),
            message,
          });
        }
      }

      break;
    }

    case NotificationCase.ABUSE_REPORTED: {
      const abuseReported = notification.getAbuseReported();

      if (abuseReported) {
        const initiator = abuseReported.getInitiator();
        const subject = abuseReported.getSubject();
        let userId = initiator?.getUserId() ?? '';
        let chatterId = subject?.getChatterId() ?? '';

        if (initiator?.getUserCase() === UserCase.CHATTER_ID) {
          chatterId = initiator?.getChatterId() ?? '';
          userId = subject?.getUserId() ?? '';
        }

        commit('mutationChatSetDialogBlocked', {
          chatterId,
          userId,
        });
      }

      break;
    }

    case NotificationCase.USER_BLOCKED: {
      const userBlocked = notification.getUserBlocked();

      if (userBlocked) {
        const initiator = userBlocked.getInitiator();
        const subject = userBlocked.getSubject();
        let userId = initiator?.getUserId() ?? '';
        let chatterId = subject?.getChatterId() ?? '';

        if (initiator?.getUserCase() === UserCase.CHATTER_ID) {
          chatterId = initiator?.getChatterId() ?? '';
          userId = subject?.getUserId() ?? '';
        }

        commit('mutationChatSetDialogBlocked', {
          chatterId,
          userId,
        });
      }

      break;
    }

    case NotificationCase.DIALOG_DELETED: {
      const dialogDeleted = notification.getDialogDeleted();

      if (dialogDeleted) {
        commit('mutationChatSetDialogDeleted', dialogDeleted.getDialogId());
      }

      break;
    }

    case NotificationCase.SESSION_STARTED: {
      const memberId = notification.getSessionStarted()?.getTeamMemberId() ?? '';

      if (memberId === getMemberId()) {
        dispatch('actionChatGetSession');
        commit('mutationChatSessionStarted');
        startSessionKeepAlive();
        hideSessionSpinner();
      }
      break;
    }

    case NotificationCase.SESSION_ENDED: {
      const memberId = notification.getSessionEnded()?.getTeamMemberId() ?? '';

      if (memberId === getMemberId()) {
        dispatch('actionChatGetSession');
        // setUserChannelBoundary('');
        // websocketChannelResubscribe();
        commit('mutationChatSessionStopped');
        stopSessionKeepAlive();
        hideSessionSpinner();
        hideAllModals();
      }
      break;
    }

    case NotificationCase.SESSION_NEARLY_EXPIRED: {
      const nearlyExpired = notification.getSessionNearlyExpired();
      if (nearlyExpired) {
        const memberId = nearlyExpired.getTeamMemberId();

        if (memberId === getMemberId()) {
          const seconds = nearlyExpired.getValidUntilDuration()?.getSeconds() ?? 0;
          const { showModal } = useModal({
            inactive: {
              component: SessionInactiveModal,
              props: {
                durationMs: seconds * 1000,
              },
            },
          });
          showModal('inactive');
        }
      }
      break;
    }

    case NotificationCase.SESSION_STATUS_UPDATED: {
      const memberId = notification.getSessionStatusUpdated()?.getTeamMemberId() ?? '';
      const status = notification.getSessionStatusUpdated()?.getStatus() ?? Status.STATUS_INVALID;

      if (memberId === getMemberId()) {
        dispatch('actionChatGetSession').then((session) => {
          if (session) {
            if (status === Status.STATUS_ON_BREAK) {
              showSessionBreakModal({
                session,
              });
            } else if (status === Status.STATUS_ACTIVE) {
              hideSessionBreakModal();
            }
          }
        });
      }
      break;
    }

    default:
      break;
  }
};
