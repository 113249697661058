import { Session } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';

import SessionBreak from '@/components/session/session-break-modal/SessionBreakModal.vue';
import useModal from '@/services/modal/modal';

let modal: {
  hideModal: (name: 'sessionBreak') => void;
  showModal: (name: 'sessionBreak') => void;
} | null = null;

type ShowSessionBreakModalArg = {
  session: Session.AsObject;
};
export function showSessionBreakModal({ session }: ShowSessionBreakModalArg) {
  modal = useModal({
    sessionBreak: {
      component: SessionBreak,
      props: {
        session,
      },
    },
  });

  modal.showModal('sessionBreak');
}

export function hideSessionBreakModal() {
  if (modal) {
    modal.hideModal('sessionBreak');
  }
}
