import { useUiEvent } from '@/hooks/use-ui-event/use-ui-event';

type UseSelfArg = {
  handleClose: () => void;
  closeOnEsc: boolean;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const uiEvent = useUiEvent({
    handleEscapeKeyDown() {
      arg.handleClose();
    },
  });

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function init() {
    if (arg.closeOnEsc) {
      uiEvent.init();
    }
  }

  function destroy() {
    if (arg.closeOnEsc) {
      uiEvent.destroy();
    }
  }

  return {
    init,
    destroy,
  };
}
