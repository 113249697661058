import {
  GetDialogRequest,
  GetDialogResponse,
  ListDialogMessagesRequest,
  ListDialogMessagesResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_api_pb';
import { DialogsAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/Dialogs_apiServiceClientPb';
import { DialogIdentity } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const dialogAPIClient = new DialogsAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([dialogAPIClient]);

export type ListDialogsArgumentBackoffice = {
  limit: number;
  afterPosition: string;
};

export function getDialog({
  userId,
  dialogId,
}: DialogIdentity.AsObject): Promise<GetDialogResponse> {
  const identity = new DialogIdentity();
  identity.setUserId(userId);
  identity.setDialogId(dialogId);

  const request = new GetDialogRequest();
  request.setIdentity(identity);

  return makeRpcPromisable(dialogAPIClient, dialogAPIClient.getDialog, request);
}

export function listDialogMessages({
  limit,
  afterPosition,
  userId,
  dialogId,
}: ListDialogsArgumentBackoffice & DialogIdentity.AsObject): Promise<ListDialogMessagesResponse> {
  const identity = new DialogIdentity();
  identity.setUserId(userId);
  identity.setDialogId(dialogId);

  const cursor = new CursorPaginationRequest();
  cursor.setLimit(limit);
  cursor.setAfterPosition(afterPosition);

  const request = new ListDialogMessagesRequest();
  request.setCursor(cursor);
  request.setIdentity(identity);

  return makeRpcPromisable(dialogAPIClient, dialogAPIClient.listDialogMessages, request);
}
