import { GetNotesCountResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_api_pb';
import { Note } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_pb';

import { NotesState } from '@/components/notes/store/notes-state';
import { NotesAddMutationPayload } from '@/components/notes/types';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationNotesAdd: NotesAddMutationPayload;
    mutationNotesClearList: never;
    mutationNotesAddNotesCount: GetNotesCountResponse.AsObject;
    mutationNotesDelete: string;
    mutationNotesUpdate: Note.AsObject;
    mutationProfileNotesAdd: NotesAddMutationPayload;
    mutationProfileNotesClearList: never;
    mutationProfileNotesAddNotesCount: GetNotesCountResponse.AsObject;
    mutationProfileNotesDelete: string;
    mutationProfileNotesUpdate: Note.AsObject;
  }
}

export function mutationNotesAdd(
  state: NotesState,
  { notes, totalPages = 0 }: NotesAddMutationPayload,
): void {
  const filtered = notes.filter(
    (note: Note.AsObject) => !state.notes.some((item) => item.id === note.id),
  );
  state.notes.push(...filtered);
  state.totalPages = totalPages;
}

export function mutationNotesUpdate(state: NotesState, note: Note.AsObject): void {
  const index = state.notes.findIndex((item) => item.id === note.id);

  if (index >= 0) {
    state.notes.splice(index, 1, note);
  }
}

export function mutationNotesClearList(state: NotesState): void {
  state.notes = [];
}

export function mutationNotesAddNotesCount(
  state: NotesState,
  payload: GetNotesCountResponse.AsObject,
) {
  state.count = payload.count;
}

export function mutationNotesDelete(state: NotesState, id: string) {
  const index = state.notes.findIndex((item) => item.id === id);

  if (index >= 0) {
    state.notes.splice(index, 1);
  }
}

export function mutationProfileNotesAdd(
  state: NotesState,
  { notes, totalPages = 0 }: NotesAddMutationPayload,
): void {
  const filtered = notes.filter(
    (note: Note.AsObject) => !state.profileNotes.some((item) => item.id === note.id),
  );
  state.profileNotes.push(...filtered);
  state.totalPages = totalPages;
}

export function mutationProfileNotesUpdate(state: NotesState, note: Note.AsObject): void {
  const index = state.profileNotes.findIndex((item) => item.id === note.id);

  if (index >= 0) {
    state.profileNotes.splice(index, 1, note);
  }
}

export function mutationProfileNotesClearList(state: NotesState): void {
  state.profileNotes = [];
}

export function mutationProfileNotesDelete(state: NotesState, id: string) {
  const index = state.profileNotes.findIndex((item) => item.id === id);

  if (index >= 0) {
    state.profileNotes.splice(index, 1);
  }
}
