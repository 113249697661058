import {
  DeleteNoteRequest,
  DeleteNoteResponse,
  GetNoteRequest,
  GetNoteResponse,
  GetNotesCountRequest,
  GetNotesCountResponse,
  ListNotesRequest,
  ListNotesResponse,
  SaveDialogNoteRequest,
  SaveDialogNoteResponse,
  SaveProfileNoteRequest,
  SaveProfileNoteResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_api_pb';
import { NotesAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/Notes_apiServiceClientPb';
import {
  Identity,
  ListNotesFilter,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_pb';
import { PaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/pagination_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const notesAPIClient = new NotesAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([notesAPIClient]);

export function fetchNotes(
  params: ListNotesRequest.AsObject & { noteType: number },
): Promise<ListNotesResponse> {
  const request = new ListNotesRequest();
  const identity = new Identity();
  const pagination = new PaginationRequest();
  const filter = new ListNotesFilter();

  filter.setNoteType(params.noteType);
  if (params.identity?.dialogId) {
    identity.setDialogId(params.identity.dialogId);
  }
  if (params.identity?.userId) {
    identity.setUserId(params.identity.userId);
  }
  pagination.setPageSize(params.pagination?.pageSize ?? 10);
  pagination.setPage(params.pagination?.page ?? 1);
  request.setIdentity(identity);
  request.setPagination(pagination);
  request.setFilter(filter);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.listNotes, request);
}

export function saveNote(params: SaveDialogNoteRequest.AsObject): Promise<SaveDialogNoteResponse> {
  const request = new SaveDialogNoteRequest();

  request.setId(params.id);
  request.setDialogId(params.dialogId);
  request.setContent(params.content);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.saveDialogNote, request);
}

export function deleteNote(id: string): Promise<DeleteNoteResponse> {
  const request = new DeleteNoteRequest();
  request.setId(id);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.deleteNote, request);
}

export async function getNote(id: string): Promise<GetNoteResponse> {
  const request = new GetNoteRequest();
  request.setId(id);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.getNote, request);
}

export function getNotesCount(params: Identity.AsObject): Promise<GetNotesCountResponse> {
  const request = new GetNotesCountRequest();
  const identity = new Identity();
  identity.setUserId(params.userId);
  identity.setDialogId(params.dialogId);
  request.setIdentity(identity);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.getNotesCount, request);
}

export function fetchProfileNotes(params: ListNotesRequest.AsObject): Promise<ListNotesResponse> {
  const request = new ListNotesRequest();
  const pagination = new PaginationRequest();
  const identity = new Identity();

  identity.setUserId(params?.identity?.userId ?? '');
  pagination.setPageSize(params.pagination?.pageSize ?? 10);
  pagination.setPage(params.pagination?.page ?? 1);
  request.setPagination(pagination);
  request.setIdentity(identity);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.listNotes, request);
}

export function saveProfileNote(
  params: SaveProfileNoteRequest.AsObject,
): Promise<SaveProfileNoteResponse> {
  const request = new SaveProfileNoteRequest();
  request.setId(params.id);
  request.setContent(params.content);
  request.setUserId(params.userId);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.saveProfileNote, request);
}

export function deleteProfileNote(id: string): Promise<DeleteNoteResponse> {
  const request = new DeleteNoteRequest();
  request.setId(id);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.deleteNote, request);
}

export async function getProfileNote(id: string): Promise<GetNoteResponse> {
  const request = new GetNoteRequest();
  request.setId(id);

  return makeRpcPromisable(notesAPIClient, notesAPIClient.getNote, request);
}
