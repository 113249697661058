import {
  ListReportsRequest,
  ListReportsResponse,
  ResolveReportRequest,
  ResolveReportResponse,
  UpdateCommentRequest,
  UpdateCommentResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_abuse_api_pb';
import { ReportAbuseAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/Report_abuse_apiServiceClientPb';
import {
  ListReportsFilter,
  ReportStatusFilter,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_pb';
import { PaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/pagination_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const reportAbuseAPIClient = new ReportAbuseAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([reportAbuseAPIClient]);

export type ListReportsArguments = {
  filter?: string;
  subjectId?: string;
  initiatorId?: string;
  status?: ReportStatusFilter;
  pagination: PaginationRequest.AsObject;
  resolvedBy?: string;
  createdTime?: ListReportsFilter.DateRange.AsObject;
};

export type ResolveReportArguments = {
  reportId: string;
  comment: string;
};

export function listReports({
  filter,
  subjectId,
  initiatorId,
  status,
  resolvedBy,
  createdTime,
  pagination: { page, pageSize },
}: ListReportsArguments): Promise<ListReportsResponse> {
  const request = new ListReportsRequest();
  const filterRequest = new ListReportsFilter();
  const pagination = new PaginationRequest().setPage(page).setPageSize(pageSize);

  request.setPagination(pagination);

  if (filter) {
    const filterValue = new StringValue();
    filterValue.setValue(filter);
    filterRequest.setFilter(filterValue);
  }

  if (subjectId) {
    const subjectIdValue = new StringValue();
    subjectIdValue.setValue(subjectId);
    filterRequest.setSubjectId(subjectIdValue);
  }

  if (initiatorId) {
    const initiatorIdValue = new StringValue();
    initiatorIdValue.setValue(initiatorId);
    filterRequest.setInitiatorId(initiatorIdValue);
  }

  if (status) {
    filterRequest.setStatus(status);
  }

  if (resolvedBy) {
    const resolvedByValue = new StringValue();
    resolvedByValue.setValue(resolvedBy);
    filterRequest.setResolvedBy(resolvedByValue);
  }

  if (createdTime) {
    const createdTimeValue = new ListReportsFilter.DateRange();
    createdTimeValue.setFromTime(new Timestamp().setSeconds(createdTime.fromTime?.seconds ?? 0));
    createdTimeValue.setToTime(new Timestamp().setSeconds(createdTime.toTime?.seconds ?? 0));

    filterRequest.setCreatedTime(createdTimeValue);
  }

  request.setFilter(filterRequest);

  return makeRpcPromisable(reportAbuseAPIClient, reportAbuseAPIClient.listReports, request);
}

export function resolveReport({
  reportId,
  comment,
  isComplaintJustified,
  complaintCategory,
}: ResolveReportRequest.AsObject): Promise<ResolveReportResponse.AsObject> {
  const request = new ResolveReportRequest();
  request.setReportId(reportId);
  request.setComment(comment);
  request.setIsComplaintJustified(isComplaintJustified);
  request.setComplaintCategory(complaintCategory);

  return makeRpcPromisable(reportAbuseAPIClient, reportAbuseAPIClient.resolveReport, request);
}

export function updateComment({
  reportId,
  comment,
  isComplaintJustified,
  complaintCategory,
}: UpdateCommentRequest.AsObject): Promise<UpdateCommentResponse> {
  const request = new UpdateCommentRequest();
  request.setReportId(reportId);
  request.setComment(comment);
  request.setIsComplaintJustified(isComplaintJustified);
  request.setComplaintCategory(complaintCategory);

  return makeRpcPromisable(reportAbuseAPIClient, reportAbuseAPIClient.updateComment, request);
}
