import { Scenario } from '@project-gd-x/dating-backoffice-contracts/contracts/gd-dispatcher-api/gdx/automess/v2/scenario_pb';
import { BatchSetScenariosActiveRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gd-dispatcher-api/gdx/automess/v2/scenarios_api_pb';
import { BehaviorPreset } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/automation_pb';
import { ReportUserAbuseResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_api_pb';
import { Dialog } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/dialog_pb';
import {
  DialogIdentity,
  DialogPriority,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import {
  Session,
  Status,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import { Gift } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/market/v1/gift_pb';
import { ActionContext } from 'vuex';

import { SceneFilterActive } from '@/pages/chat/components/chat-dispatcher/helpers/type/type';
import {
  ChatState,
  ChatStateDialog,
  ChatStateDialogMessage,
  ChatStateDialogMessageContent,
  ChatStateManagementSession,
} from '@/pages/chat/store/chat-state';
import {
  CHATTERS_MANAGEMENT_SESSIONS_LIMIT,
  DIALOGS_LIMIT,
  getChattersManagementSessionsFrom,
  getDialogFrom,
  getDialogsFrom,
  getMessagesFrom,
  getMyMessageFrom,
  MESSAGES_LIMIT,
} from '@/pages/chat/store/helpers/chat-helper';
import { ProfileStateAbuseReason } from '@/pages/report-abuse/store/report-abuse-state';
import {
  deleteMessages,
  getDialog,
  getSession,
  listDialogMacros,
  listDialogMessages,
  listDialogs,
  ListDialogsArgumentUser,
  skipDialog,
  reportSuspiciousContent,
  reportUserAbuse,
  sendMessage,
  setSessionStatus,
} from '@/services/api/grpc/chatters-api/chatters-api';
import { chattersAutomationApi } from '@/services/api/grpc/chatters-automation-api/chatters-automation-api';
import {
  listSessions,
  terminateSession,
} from '@/services/api/grpc/chatters-management-api/chatters-management-api';
import { SCENARIO_LIMIT, scenariosApi } from '@/services/api/grpc/gd-dispatcher-api/scenarios-api';
import { listGifts } from '@/services/api/grpc/market-api/market-api';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit } from '@/store/store-helper';

export type ActionChatListDialogsPayload = {
  user?: ListDialogsArgumentUser;
  filter: string;
  priority?: DialogPriority;
};

export type ActionChatSendMessagePayload = {
  identity: DialogIdentity.AsObject;
  message: ChatStateDialogMessageContent;
  userId: string;
  isMacro: boolean;
};

export type ActionChatDeleteMessagesPayload = {
  chatterId: string;
  chatId: string;
  messageIds: string[];
};

export type ActionChatSubmitUserAbusePayload = {
  chatterId: string;
  userId: string;
  reason: ProfileStateAbuseReason;
  description: string;
};

export type ActionChatSuspiciousContentReportedPayload = {
  chatterId: string;
  chatId: string;
  messageId: string;
};

export type ActionChatListDialogMessagesPayload = {
  identity: DialogIdentity.AsObject;
  displayDeletedMessages: boolean;
};

declare module '@/store/store-helper' {
  interface Action {
    actionChatListDialogs: ActionFunction<ActionChatListDialogsPayload, ChatStateDialog[]>;
    actionChatListDialogMessages: ActionFunction<
      ActionChatListDialogMessagesPayload,
      ChatStateDialogMessage[]
    >;
    actionChatGetDialog: ActionFunction<DialogIdentity.AsObject, ChatStateDialog>;
    actionChatSendMessage: ActionFunction<ActionChatSendMessagePayload, ChatStateDialogMessage>;
    actionChatListGifts: ActionFunction<never, Gift.AsObject[]>;
    actionChatSkipDialog: ActionFunction<DialogIdentity.AsObject, void>;
    actionChatDeleteMessages: ActionFunction<ActionChatDeleteMessagesPayload, void>;
    actionChatSuspiciousContentReported: ActionFunction<
      ActionChatSuspiciousContentReportedPayload,
      void
    >;
    actionChatSubmitUserAbuse: ActionFunction<ActionChatSubmitUserAbusePayload, void>;
    actionChatGetSession: ActionFunction<never, Session.AsObject | undefined>;
    actionChatSetSessionStatus: ActionFunction<Status, void>;
    actionChatManagementListSessions: ActionFunction<never, ChatStateManagementSession[]>;
    actionChatManagementTerminateSession: ActionFunction<string, void>;
    actionChatListDialogMacros: ActionFunction<DialogIdentity.AsObject, string[]>;
    actionChatFetchPresetList: ActionFunction<
      ActionChatFetchPresetListArg,
      BehaviorPreset.AsObject[]
    >;

    actionChatFetchScenarioList: ActionFunction<SceneFilterActive, Scenario.AsObject[]>;
    actionChatDeleteScenario: ActionFunction<string, void>;
    actionChatSetScenarioIsActiveList: ActionFunction<
      BatchSetScenariosActiveRequest.AsObject,
      void
    >;
  }
}

export async function actionChatSubmitUserAbuse(
  { state }: ActionContext<ChatState, unknown>,
  { chatterId, userId, reason, description }: ActionChatSubmitUserAbusePayload,
): Promise<ReportUserAbuseResponse.AsObject> {
  const abuseResponse = await reportUserAbuse({ userId, chatterId, description, reason });

  // in any case of blocking will change dialog property isSubjectBlocked
  commit('mutationChatSetDialogBlocked', {
    chatterId,
    userId,
  });

  return abuseResponse.toObject();
}

export async function actionChatListDialogs(
  { state }: ActionContext<ChatState, unknown>,
  { filter, user, priority }: ActionChatListDialogsPayload,
): Promise<ChatStateDialog[]> {
  const response = await listDialogs({
    afterPosition: state.dialogsAfter,
    limit: DIALOGS_LIMIT,
    filter,
    user,
    priority,
  });
  const dialogs = getDialogsFrom(response);
  commit('mutationChatAddDialogs', dialogs);
  commit('mutationChatSetDialogsAfter', response.getCursor()?.getNext() ?? '');

  return dialogs;
}

export async function actionChatListDialogMessages(
  { state }: ActionContext<ChatState, unknown>,
  { identity, displayDeletedMessages }: ActionChatListDialogMessagesPayload,
): Promise<ChatStateDialogMessage[]> {
  const response = await listDialogMessages({
    cursor: { limit: MESSAGES_LIMIT, afterPosition: state.messagesAfter },
    identity,
    displayDeletedMessages,
  });
  const messages = getMessagesFrom(response);
  commit('mutationChatAddMessages', {
    dialogId: identity.dialogId,
    messages,
  });
  commit('mutationChatSetMessagesAfter', response.getCursor()?.getNext() ?? '');

  return messages;
}

export async function actionChatGetDialog(
  { state }: ActionContext<ChatState, unknown>,
  identity: DialogIdentity.AsObject,
): Promise<ChatStateDialog> {
  const response = await getDialog(identity);
  const location = response.getHomelessLocation()?.toObject();
  const teamMemberEmail = response.getTeamMemberEmail();

  const dialog = getDialogFrom(response.getDialog() as Dialog, location, teamMemberEmail);

  commit('mutationChatSetDialog', dialog);

  return dialog;
}

export async function actionChatSendMessage(
  { state }: ActionContext<ChatState, unknown>,
  { identity, message, userId, isMacro }: ActionChatSendMessagePayload,
): Promise<ChatStateDialogMessage> {
  const result = await sendMessage(identity, message, isMacro);
  const myMessage = getMyMessageFrom(identity, message, result, userId);
  commit('mutationChatAddMessage', {
    message: myMessage,
    dialogId: identity.dialogId,
  });

  return myMessage;
}

export async function actionChatListGifts({
  state,
}: ActionContext<ChatState, unknown>): Promise<Gift.AsObject[]> {
  const result = await listGifts();
  const gifts = result.toObject().giftsList;
  commit('mutationChatSetGifts', gifts);

  return gifts;
}

export async function actionChatSkipDialog(
  { state }: ActionContext<ChatState, unknown>,
  identity: DialogIdentity.AsObject,
): Promise<void> {
  await skipDialog(identity);
  commit('mutationChatMakeAsRead', identity);
}

export async function actionChatDeleteMessages(
  { state }: ActionContext<ChatState, unknown>,
  { chatterId, chatId, messageIds }: ActionChatDeleteMessagesPayload,
): Promise<void> {
  await deleteMessages(chatterId, chatId, messageIds);

  commit('mutationChatDeleteMessages', {
    chatId,
    messageIds,
  });
}

export async function actionChatSuspiciousContentReported(
  { state }: ActionContext<ChatState, unknown>,
  { chatterId, chatId, messageId }: ActionChatSuspiciousContentReportedPayload,
): Promise<void> {
  await reportSuspiciousContent(chatterId, chatId, messageId);

  commit('mutationChatSuspiciousContentReported', {
    dialogId: chatId,
    messageId,
  });
}

export async function actionChatGetSession({
  state,
}: ActionContext<ChatState, unknown>): Promise<Session.AsObject | undefined> {
  try {
    const response = await getSession();
    const session = response.getSession()?.toObject();
    commit('mutationChatSetSession', session);

    return session;
  } catch (e) {
    sentryCaptureMessage({
      message: 'SESSION_ERROR',
      captureContext: { extra: { e } },
    });
    commit('mutationChatSetSession', undefined);
  }
}

export async function actionChatSetSessionStatus(
  { state }: ActionContext<ChatState, unknown>,
  status: Status,
): Promise<void> {
  await setSessionStatus(status);
  commit('mutationChatSetSessionStatus', status);
}

export async function actionChatManagementListSessions({
  state,
}: ActionContext<ChatState, unknown>): Promise<ChatStateManagementSession[]> {
  const response = await listSessions(
    CHATTERS_MANAGEMENT_SESSIONS_LIMIT,
    state.managementSessionsAfter,
  );
  const sessions = getChattersManagementSessionsFrom(response);

  commit('mutationChatManagementAddSessions', sessions);
  commit('mutationChatManagementSetSessionsAfter', response.getCursor()?.getNext() ?? '');

  return sessions;
}

export async function actionChatManagementTerminateSession(
  { state }: ActionContext<ChatState, unknown>,
  teamMemberId: string,
): Promise<void> {
  await terminateSession(teamMemberId);
  commit('mutationChatManagementDeleteSession', teamMemberId);
}

export async function actionChatListDialogMacros(
  { state }: ActionContext<ChatState, unknown>,
  identity: DialogIdentity.AsObject,
): Promise<string[]> {
  const resp = await listDialogMacros(identity);
  return resp.getMacrosList();
}

type ActionChatFetchPresetListArg = {
  titleFilter: string;
  limit: number;
};
export async function actionChatFetchPresetList(
  { state }: ActionContext<ChatState, unknown>,
  arg: ActionChatFetchPresetListArg,
): Promise<BehaviorPreset.AsObject[]> {
  const response = await chattersAutomationApi.listBehaviorPresets({
    title: arg.titleFilter,
    cursor: {
      limit: arg.limit,
      afterPosition: state.presetNextId,
    },
  });

  const presetResponse = response.toObject();
  const presetPageList = presetResponse.presetsList;

  commit('mutationChatAddPresetList', presetPageList);
  commit('mutationChatSetPresetNextId', presetResponse.cursor?.next ?? '');

  return presetPageList;
}

export async function actionChatFetchScenarioList(
  { state }: ActionContext<ChatState, unknown>,
  filterActive: SceneFilterActive,
): Promise<Scenario.AsObject[]> {
  const response = await scenariosApi.listScenarios({
    from: state.scenarioFrom,
    limit: SCENARIO_LIMIT,
    filterActive,
  });
  const scenarioList = response.toObject().scenariosList;

  commit('mutationChatSetScenarioFrom', response.getPagination()?.getNext() ?? '');
  commit('mutationChatAddScenarioList', scenarioList);

  return scenarioList;
}

export async function actionChatDeleteScenario(
  { state }: ActionContext<ChatState, unknown>,
  scenarioId: string,
): Promise<void> {
  await scenariosApi.deleteScenario(scenarioId);

  commit('mutationChatDeleteScenario', scenarioId);
}

export async function actionChatSetScenarioIsActiveList(
  { state }: ActionContext<ChatState, unknown>,
  arg: BatchSetScenariosActiveRequest.AsObject,
): Promise<void> {
  await scenariosApi.batchSetScenariosActive(arg);
  commit('mutationChatSetScenarioIsActiveList', arg);
}
