import {
  KeywordsState,
  KeywordsStateProfile,
  KeywordStateKeyword,
} from '@/pages/keywords/store/keywords-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationKeywordsAddProfilesMatches: KeywordsStateProfile[];
    mutationKeywordsAddProfilesAll: KeywordsStateProfile[];
    mutationKeywordsProfilesMatchesClear: never;
    mutationKeywordsProfilesAllClear: never;
    mutationKeywordsProfilesMatchesSetSearchAfter: string;
    mutationKeywordsProfilesAllSetSearchAfter: string;
    mutationKeywordsDeleteProfileMatch: string;
    mutationKeywordsAddKeywords: KeywordStateKeyword[];
    mutationKeywordsKeywordsClear: never;
  }
}

export function mutationKeywordsAddProfilesMatches(
  state: KeywordsState,
  profiles: KeywordsStateProfile[],
) {
  state.profilesMatches.push(...profiles);
}

export function mutationKeywordsAddProfilesAll(
  state: KeywordsState,
  profiles: KeywordsStateProfile[],
) {
  state.profilesAll.push(...profiles);
}

export function mutationKeywordsProfilesMatchesClear(state: KeywordsState) {
  state.profilesMatches = [];
}

export function mutationKeywordsProfilesAllClear(state: KeywordsState) {
  state.profilesAll = [];
}

export function mutationKeywordsProfilesMatchesSetSearchAfter(
  state: KeywordsState,
  searchAfter: string,
) {
  state.profilesMatchesSearchAfter = searchAfter;
}

export function mutationKeywordsProfilesAllSetSearchAfter(
  state: KeywordsState,
  searchAfter: string,
) {
  state.profilesAllSearchAfter = searchAfter;
}

export function mutationKeywordsDeleteProfileMatch(state: KeywordsState, profileId: string) {
  const index = state.profilesMatches.findIndex((profileMatch) => profileMatch.id === profileId);
  if (index !== -1) {
    state.profilesMatches.splice(index, 1);
  }
}

export function mutationKeywordsAddKeywords(state: KeywordsState, keywords: KeywordStateKeyword[]) {
  state.keywords = [...keywords, ...state.keywords];
}

export function mutationKeywordsKeywordsClear(state: KeywordsState) {
  state.keywords = [];
}
