import { GetProfilesResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/newprofiles/v1/new_profiles_api_pb';

export type NewProfilesStateProfile = GetProfilesResponse.Profile.AsObject;

export type NewProfilesState = {
  newProfiles: NewProfilesStateProfile[];
  queueLength: number;
};

export const state: NewProfilesState = {
  newProfiles: [],
  queueLength: 0,
};
