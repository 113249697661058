import { ResolveReportRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_abuse_api_pb';

import { MemberStateMember } from '@/pages/member/store/member-state';
import {
  ReportAbuseState,
  ReportAbuseStateDialog,
  ReportAbuseStateDialogMessage,
  ResolutionResolved,
} from '@/pages/report-abuse/store/report-abuse-state';

type MutationReportResolveReportAbuse = ResolveReportRequest.AsObject & {
  resolvedBy: MemberStateMember;
};

type MutationReportAddMessagesPayload = {
  messages: ReportAbuseStateDialogMessage[];
  dialogId: string;
};

declare module '@/store/store-helper' {
  interface Mutation {
    mutationReportResolveReportAbuse: MutationReportResolveReportAbuse;
    mutationReportSetMessagesAfter: string;
    mutationReportAddMessages: MutationReportAddMessagesPayload;
    mutationReportClearMessages: string;

    mutationReportSetDialog: ReportAbuseStateDialog;
    mutationReportSetMessagesHeaderDialog: ReportAbuseStateDialog;
  }
}

export function mutationReportResolveReportAbuse(
  state: ReportAbuseState,
  { reportId, comment, resolvedBy }: MutationReportResolveReportAbuse,
): void {
  const report = state.reportsList.find((report) => report.id === reportId);

  if (report) {
    report.resolution = {
      type: 'resolved',
      comment,
      resolvedTimeMs: Date.now(),
      resolvedBy,
    } as ResolutionResolved;
  }
}

export function mutationReportSetMessagesAfter(
  state: ReportAbuseState,
  messagesAfter: string,
): void {
  state.messagesAfter = messagesAfter;
}

// it's a duplicate with chat state
export function mutationReportAddMessages(
  state: ReportAbuseState,
  { messages, dialogId }: MutationReportAddMessagesPayload,
): void {
  if (!state.messages[dialogId]) {
    state.messages[dialogId] = [];
  }

  const stateMessages = state.messages[dialogId];
  messages.forEach((message) => {
    const index = stateMessages.findIndex((stateMessage) => stateMessage.id === message.id);
    if (index !== -1) {
      stateMessages[index] = message;
    } else {
      stateMessages.unshift(message);
    }
  });
}

export function mutationReportClearMessages(state: ReportAbuseState, dialogId: string): void {
  state.messages[dialogId] = [];
}

export function mutationReportSetDialog(
  state: ReportAbuseState,
  dialog: ReportAbuseStateDialog,
): void {
  const dialogIndex = state.dialogs.findIndex((item) => item.id === dialog.id);

  if (dialogIndex !== -1) {
    state.dialogs[dialogIndex] = dialog;
  }
}

export function mutationReportSetMessagesHeaderDialog(
  state: ReportAbuseState,
  dialog: ReportAbuseStateDialog,
): void {
  state.messagesHeaderDialog = dialog;
}
