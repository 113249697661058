import dayjs from 'dayjs';
import { computed, reactive } from 'vue';

import { getCache, setCache } from '@/helpers/persistent-cache';

export type TimerName = 'session-inactive' | 'session-break-total' | 'session-break-current';

// Global interval ID, cause it's more efficient to have 1 interval for all timers.
let intervalId: number;

export type Timer = {
  secondsLeft: number;
  expiresAtSeconds: number;
};
export type TimersCache = {
  [key: string]: {
    expiresAtSeconds: number;
  };
};
const timers = reactive<{
  [key in string]: Timer;
}>({});

let cache: string;

const TIMERS_CACHE_KEY = 'timers';

function saveTimers() {
  const expires: TimersCache = {};
  Object.keys(timers).forEach((name) => {
    expires[name] = {
      expiresAtSeconds: dayjs().unix() + timers[name].secondsLeft,
    };
  });

  setCache(TIMERS_CACHE_KEY, JSON.stringify(expires));
}

function loadTimers() {
  if (!cache) {
    cache = getCache(TIMERS_CACHE_KEY) ?? '';
    if (cache) {
      const obj = JSON.parse(cache) as TimersCache;
      Object.keys(obj).forEach((key) => {
        const { expiresAtSeconds } = obj[key];
        let secondsLeft = expiresAtSeconds - dayjs().unix();

        if (secondsLeft < 0) {
          secondsLeft = 0;
        }
        timers[key] = {
          secondsLeft,
          expiresAtSeconds,
        };
      });
    }
  }
}

function tick() {
  let isAnyTimerAlive = false;

  Object.values(timers).forEach((timer) => {
    if (timer.secondsLeft > 0) {
      timer.secondsLeft = timer.expiresAtSeconds - dayjs().unix();
      isAnyTimerAlive = true;
    }
  });

  // Stop setInterval if there is not active timers.
  if (!isAnyTimerAlive && intervalId) {
    clearInterval(intervalId);
    intervalId = 0;
  }
}

function startTimersInterval() {
  if (!intervalId) {
    intervalId = window.setInterval(tick, 1000);
  }
}

export function removeTimerCache(key: TimerName): void {
  const cache = getCache(TIMERS_CACHE_KEY);

  if (cache) {
    const obj = JSON.parse(cache) as TimersCache;

    if (obj[key]) {
      delete obj[key];
    }

    setCache(TIMERS_CACHE_KEY, JSON.stringify(obj));
  }
}

export function useTimer(timerName: TimerName) {
  // Load all timers from localStorage ( it's needed if page has been reloaded )
  loadTimers();

  /**
   * @param secondsLeft - timer duration in seconds
   * @param isForced - if we want to recreate current timer
   */
  function startTimer(secondsLeft: number, isForced = false): Timer {
    if (!timers[timerName] || isForced) {
      timers[timerName] = {
        secondsLeft,
        expiresAtSeconds: dayjs().unix() + secondsLeft,
      };
      saveTimers();
    }
    startTimersInterval();

    return timers[timerName];
  }

  const timerSecondsLeft = computed(() => timers[timerName].secondsLeft);

  return {
    startTimer,
    timerSecondsLeft,
  };
}
