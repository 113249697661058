import * as realtimeTracking from '@project-gd-x/shared-web/src/helpers/realtime-tracking/realtime-tracking';
import { PushRealtimeTrackingOptions } from '@project-gd-x/shared-web/src/helpers/realtime-tracking/realtime-tracking';

export function initRealtimeTracking() {
  realtimeTracking.initRealtimeTracking({
    endpointUrl: process.env.VUE_APP_REALTIME_TRACKING_URL as string,
  });
}

const BO_EVENTS_PREFIX = 'bo_';

export function pushRealtimeTrackingCounter(
  eventName: string,
  labels: Record<string, string> = {},
  value: number,
  options?: PushRealtimeTrackingOptions,
) {
  return realtimeTracking.pushRealtimeTrackingCounter(
    BO_EVENTS_PREFIX + eventName,
    labels,
    value,
    options,
  );
}

export function pushRealtimeTrackingHistogram(
  eventName: string,
  labels: Record<string, string> = {},
  value: number,
  options?: PushRealtimeTrackingOptions,
) {
  return realtimeTracking.pushRealtimeTrackingHistogram(
    BO_EVENTS_PREFIX + eventName,
    labels,
    value,
    options,
  );
}
