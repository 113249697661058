import { Keyword } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/keyword_pb';
import { ProfileMatches } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/profiles_pb';

export type KeywordsStateProfile = Omit<ProfileMatches.AsObject, 'updatedTime'> & {
  updatedTimeMs: number;
};

export type KeywordStateKeyword = Keyword.AsObject;

export type KeywordsState = {
  profilesMatches: KeywordsStateProfile[];
  profilesAll: KeywordsStateProfile[];
  keywords: KeywordStateKeyword[];
  profilesMatchesSearchAfter: string;
  profilesAllSearchAfter: string;
};

export const state: KeywordsState = {
  profilesMatches: [],
  profilesAll: [],
  keywords: [],
  profilesMatchesSearchAfter: '',
  profilesAllSearchAfter: '',
};
