import { I18n } from '@/services/i18n';

type Message = {
  endSession: string;
  cancel: string;
  areYouSureWantToEndYourSession: string;
};

const messages: I18n<Message> = {
  en: {
    endSession: "Yes, I'm sure",
    cancel: 'Take me back',
    areYouSureWantToEndYourSession: 'Are you sure you want to end your session?',
  },
};

export { messages };
