import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { NotesState } from '@/components/notes/store/notes-state';
import { notes } from '@/components/notes/store/notes-store';
import { CACHE_PREFIX } from '@/helpers/persistent-cache';
import { ChatState } from '@/pages/chat/store/chat-state';
import { chat, chatPersisted } from '@/pages/chat/store/chat-store';
import { KeywordsState } from '@/pages/keywords/store/keywords-state';
import { keywords, keywordsPersisted } from '@/pages/keywords/store/keywords-store';
import { MemberState } from '@/pages/member/store/member-state';
import { member, memberPersisted } from '@/pages/member/store/member-store';
import { NewProfilesState } from '@/pages/new-profiles/store/new-profiles-state';
import { newProfiles, newProfilesPersisted } from '@/pages/new-profiles/store/new-profiles-store';
import { PaymentsState } from '@/pages/payments/store/payments-state';
import { payments, paymentsPersisted } from '@/pages/payments/store/payments-store';
import { ReportAbuseState } from '@/pages/report-abuse/store/report-abuse-state';
import { reportAbuse } from '@/pages/report-abuse/store/report-abuse-store';
import { UsersState } from '@/pages/users/store/users-state';
import { users, usersPersisted } from '@/pages/users/store/users-store';
import { ErrorState } from '@/store/modules/error/error-state';

import { error, errorPersisted } from './modules/error/error-store';

export const persistedState = createPersistedState({
  paths: [
    ...memberPersisted,
    ...newProfilesPersisted,
    ...usersPersisted,
    ...errorPersisted,
    ...keywordsPersisted,
    ...chatPersisted,
    ...paymentsPersisted,
  ],
  key: `${CACHE_PREFIX}vuex`,
});

export const store = createStore<{
  member: MemberState;
  newProfiles: NewProfilesState;
  users: UsersState;
  error: ErrorState;
  keywords: KeywordsState;
  chat: ChatState;
  reportAbuse: ReportAbuseState;
  payments: PaymentsState;
  notes: NotesState;
}>({
  modules: {
    member,
    newProfiles,
    users,
    error,
    keywords,
    chat,
    reportAbuse,
    payments,
    notes,
  },
  plugins: [persistedState],
});
