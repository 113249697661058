import { ActionContext } from 'vuex';

import { NEW_PROFILES_LIMIT } from '@/pages/new-profiles/store/helpers/new-profiles-helper';
import {
  NewProfilesState,
  NewProfilesStateProfile,
} from '@/pages/new-profiles/store/new-profiles-state';
import {
  getProfiles,
  makeDecision,
  MakeDecisionResult,
} from '@/services/api/grpc/new-profiles/new-profiles-api';
import { commit, ActionFunction } from '@/store/store-helper';

type ActionNewProfilesMakeDecision = {
  results: MakeDecisionResult[];
  isAiDeclined: boolean;
};

declare module '@/store/store-helper' {
  interface Action {
    actionNewProfilesGetNewProfiles: ActionFunction<boolean, NewProfilesStateProfile[]>;
    actionNewProfilesMakeDecision: ActionFunction<ActionNewProfilesMakeDecision, void>;
  }
}

export async function actionNewProfilesGetNewProfiles(
  { state }: ActionContext<NewProfilesState, unknown>,
  isAiDeclined: boolean,
): Promise<NewProfilesStateProfile[]> {
  const response = await getProfiles(NEW_PROFILES_LIMIT, isAiDeclined);
  const profiles = response.toObject().profilesList;

  commit('mutationNewProfilesSetProfiles', profiles);
  commit('mutationNewProfilesSetQueueLength', response.getQueueLength());

  return profiles;
}

export async function actionNewProfilesMakeDecision(
  { state }: ActionContext<NewProfilesState, unknown>,
  { results, isAiDeclined }: ActionNewProfilesMakeDecision,
): Promise<void> {
  await makeDecision(results, isAiDeclined);
}
