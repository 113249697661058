import {
  NewProfilesState,
  NewProfilesStateProfile,
} from '@/pages/new-profiles/store/new-profiles-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationNewProfilesSetProfiles: NewProfilesStateProfile[];
    mutationNewProfilesSetQueueLength: number;
    mutationNewProfilesClearProfiles: never;
  }
}

export function mutationNewProfilesSetProfiles(
  state: NewProfilesState,
  newProfiles: NewProfilesStateProfile[],
) {
  state.newProfiles = newProfiles;
}

export function mutationNewProfilesSetQueueLength(state: NewProfilesState, queueLength: number) {
  state.queueLength = queueLength;
}

export function mutationNewProfilesClearProfiles(state: NewProfilesState) {
  state.newProfiles = [];
}
