type NavigateOptions = {
  isNewTab?: boolean;
};
export function navigate(url: string, options?: NavigateOptions): void {
  if (options?.isNewTab) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  } else {
    window.location.href = url;
  }
}

export function navigateToGoogleAuth(backUrl: string): void {
  const url = encodeURIComponent(backUrl);
  navigate(`${process.env.VUE_APP_AUTH_URL}/api/login?return_url=${url}`);
}

export function navigateToSignout(backUrl: string): void {
  const url = encodeURIComponent(backUrl);
  navigate(
    `${process.env.VUE_APP_AUTH_URL}/api/login?gcp-iap-mode=CLEAR_LOGIN_COOKIE&return_url=${url}`,
  );
}

export function getChatAutologinUrl(oneTimeCredentialHash: string): string {
  const redirectUrl = `${process.env.VUE_APP_SITE_URL}/chat/list`;
  return `${
    process.env.VUE_APP_SITE_URL
  }/signin/autologin/${oneTimeCredentialHash}/redirect/${encodeURIComponent(redirectUrl)}`;
}
