import * as gdx_backoffice_web_team_v1_team_member_pb from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';
import {
  Feature,
  Role,
  Status,
  TeamMember,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';
import * as jspb from 'google-protobuf';

import { MemberStateMember } from '@/pages/member/store/member-state';
import { store } from '@/store';
import { dispatch } from '@/store/store-helper';

let isMemberLoaded = false;
export async function getAndSaveMemberInfo() {
  if (!isMemberLoaded) {
    await dispatch('actionMemberGetInfo');
    isMemberLoaded = true;
  }
}

export function getMemberFrom(member?: TeamMember.AsObject): MemberStateMember {
  return {
    id: member?.id ?? '',
    avatarUrl: member?.avatarUrl ?? '',
    email: member?.email ?? '',
    fullName: member?.fullName ?? '',
    role: member?.role ?? Role.ROLE_INVALID,
    status: member?.status ?? Status.STATUS_INVALID,
    invitedBy: member?.invitedBy ?? '',
    createdTime: {
      seconds: member?.createdTime?.seconds ?? 0,
      nanos: member?.createdTime?.nanos ?? 0,
    },
  };
}

export function hasFeature(feature: Feature) {
  return store.state.member.features.includes(feature);
}

export function getMembersMapFrom(
  members?: jspb.Map<string, gdx_backoffice_web_team_v1_team_member_pb.TeamMember>,
): Map<string, MemberStateMember> {
  const result = new Map<string, MemberStateMember>();

  members?.forEach((member, id) => result.set(id, getMemberFrom(member.toObject())));

  return result;
}

export function getMemberEmail(): string {
  return store.state.member.member.email;
}

export function getMemberId(): string {
  return store.state.member.member.id;
}
