import {
  AccountField,
  SettingsField,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/account_pb';
import {
  BillingField,
  SubscriptionOverrides,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/billing_pb';
import {
  FeaturePossibility,
  FeatureType,
  Remains,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/features_pb';
import {
  ProfileField,
  ProfileHeight,
  ProfileLocation,
  ProfilePhoto,
  TempProfile,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/profile_pb';
import {
  DeletePhotoRequest,
  DeletePhotoResponse,
  GeneratePasswordRecoveryLinkRequest,
  GeneratePasswordRecoveryLinkResponse,
  GetAccountRequest,
  GetAccountResponse,
  GetAuditLogRequest,
  GetAuditLogResponse,
  GetBillingHistoryRequest,
  GetBillingHistoryResponse,
  GetBillingRequest,
  GetBillingResponse,
  GetExtraInfoRequest,
  GetExtraInfoResponse,
  GetOneTimeHashRequest,
  GetOneTimeHashResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetSubscriptionStatusRequest,
  GetSubscriptionStatusResponse,
  GetTempProfileRequest,
  GetTempProfileResponse,
  GiftFeaturePossibilityRequest,
  GiftFeaturePossibilityResponse,
  GiftSubscriptionRequest,
  GiftSubscriptionResponse,
  ListFeaturePossibilitiesRequest,
  ListFeaturePossibilitiesResponse,
  ListTagsRequest,
  ListTagsResponse,
  ListUsersTimezonesRequest,
  ListUsersTimezonesResponse,
  SaveTempProfileRequest,
  SaveTempProfileResponse,
  SearchUserRequest,
  SearchUserResponse,
  SendEmailRecoveryRequest,
  SendEmailRecoveryResponse,
  SetAccountRequest,
  SetAccountResponse,
  SetAccountStatusRequest,
  SetAccountStatusResponse,
  SetBillingRequest,
  SetBillingResponse,
  SetProfileRequest,
  SetProfileResponse,
  SetSettingsRequest,
  SetSettingsResponse,
  UnsubscribePremiumRequest,
  UnsubscribePremiumResponse,
  UpdateAvatarRequest,
  UpdateAvatarResponse,
  UploadPhotoRequest,
  UploadPhotoResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_api_pb';
import { UsersAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/Users_apiServiceClientPb';
import {
  Reason,
  SearchUserCriteria,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_pb';
import { AccountStatus } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';
import { SubscriptionTier } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/core/subscription/v1/subscription_pb';
import * as google_protobuf_duration_pb from 'google-protobuf/google/protobuf/duration_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { BoolValue, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { UsersStateSearchCriteria } from '@/pages/users/store/users-state';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

import RegistrationDateRange = SearchUserCriteria.RegistrationDateRange;

const usersAPIClient = new UsersAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([usersAPIClient]);

export function searchUser({
  criteria,
  limit,
  searchAfter,
}: {
  criteria?: UsersStateSearchCriteria;
  limit: number;
  searchAfter: string;
}): Promise<SearchUserResponse> {
  const request = new SearchUserRequest();
  request.setLimit(limit);
  request.setSearchAfter(searchAfter);

  if (criteria) {
    const c = new SearchUserCriteria();

    if (criteria.filter) {
      const value = new StringValue();
      value.setValue(criteria.filter);
      c.setFilter(value);
    }

    if (criteria.gender) {
      c.setGender(criteria.gender);
    }

    if (criteria.hasPhoto !== undefined) {
      const value = new BoolValue();
      value.setValue(criteria.hasPhoto);
      c.setHasPhoto(value);
    }

    if (criteria.hasPremium !== undefined) {
      const value = new BoolValue();
      value.setValue(criteria.hasPremium);
      c.setHasPremium(value);
    }

    if (criteria.hasTrial !== undefined) {
      const value = new BoolValue();
      value.setValue(criteria.hasTrial);
      c.setHasTrial(value);
    }

    if (criteria.isRegularUser !== undefined) {
      const value = new BoolValue();
      value.setValue(criteria.isRegularUser);
      c.setIsRegularUser(value);
    }

    if (criteria.lookingFor) {
      c.setLookingFor(criteria.lookingFor);
    }

    if (criteria.matchGender) {
      c.setMatchGender(criteria.matchGender);
    }

    if (criteria.sexualOrientation) {
      c.setSexualOrientation(criteria.sexualOrientation);
    }

    if (criteria.status) {
      c.setStatus(criteria.status);
    }

    if (criteria.registrationDateRange) {
      const value = new RegistrationDateRange();
      if (criteria.registrationDateRange.fromTimeMs) {
        const from = new Timestamp();
        from.setSeconds(Math.trunc(criteria.registrationDateRange.fromTimeMs / 1000));
        value.setFromTime(from);
      }
      if (criteria.registrationDateRange.toTimeMs) {
        const to = new Timestamp();
        to.setSeconds(Math.trunc(criteria.registrationDateRange.toTimeMs / 1000));
        value.setToTime(to);
      }

      if (value.hasFromTime() || value.hasToTime()) {
        c.setRegistrationDateRange(value);
      }
    }

    if (criteria.tags) {
      c.setTagsList(criteria.tags);
    }

    request.setCriteria(c);
  }

  return makeRpcPromisable(usersAPIClient, usersAPIClient.searchUser, request);
}

export function getBilling(userId: string): Promise<GetBillingResponse> {
  const request = new GetBillingRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getBilling, request);
}

export function giftSubscription({
  userId,
  tier,
  nextPackageId,
  packageId,
  unsubscribe,
  duration,
}: {
  userId: string;
  tier: SubscriptionTier;
  nextPackageId: string;
  packageId: string;
  unsubscribe: boolean;
  duration: google_protobuf_duration_pb.Duration;
}): Promise<GiftSubscriptionResponse> {
  const request = new GiftSubscriptionRequest();
  const overrides = new SubscriptionOverrides();

  if (nextPackageId.length) {
    overrides.setNextPackageId(nextPackageId);
  }

  overrides.setTier(tier);
  overrides.setDuration(duration);
  overrides.setUnsubscribe(unsubscribe);

  request.setPackageId(packageId);
  request.setOverrides(overrides);
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.giftSubscription, request);
}

export function listFeaturePossibilities(
  userId: string,
): Promise<ListFeaturePossibilitiesResponse> {
  const request = new ListFeaturePossibilitiesRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.listFeaturePossibilities, request);
}

export function giftFeaturePossibility({
  userId,
  featuresList,
}: {
  userId: string;
  featuresList: {
    [key in Partial<Exclude<FeatureType, FeatureType.FEATURE_TYPE_INVALID>>]: number;
  };
}): Promise<GiftFeaturePossibilityResponse> {
  const request = new GiftFeaturePossibilityRequest();
  request.setUserId(userId);
  const features: Array<FeaturePossibility> = Object.entries(featuresList).map(([key, value]) => {
    const feature = new FeaturePossibility();
    const remains = new Remains();

    remains.setCount(value);
    feature.setType(+key);
    feature.setCount(remains);

    return feature;
  });

  // @ts-expect-error: error in grpc
  request.setFeaturesList(features);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.giftFeaturePossibility, request);
}

export function setBilling(
  userId: string,
  reasonContent: string,
  fields: BillingField.AsObject,
): Promise<SetBillingResponse> {
  const fieldList: BillingField[] = [];
  const field1 = new BillingField();
  field1.setChangeCreditsCount(fields.changeCreditsCount);
  fieldList.push(field1);
  const field2 = new BillingField();
  field2.setChangePremiumDaysCount(fields.changePremiumDaysCount);
  fieldList.push(field2);
  const field3 = new BillingField();
  field3.setIsPremiumActive(fields.isPremiumActive);
  fieldList.push(field3);

  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new SetBillingRequest();
  request.setUserId(userId);
  request.setFieldsList(fieldList);
  request.setReason(reason);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.setBilling, request);
}

export function getBillingHistory({
  userId,
  limit,
  searchAfter,
}: GetBillingHistoryRequest.AsObject): Promise<GetBillingHistoryResponse> {
  const request = new GetBillingHistoryRequest();
  request.setUserId(userId);
  request.setLimit(limit);
  request.setSearchAfter(searchAfter);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getBillingHistory, request);
}

export function getProfile(userId: string): Promise<GetProfileResponse> {
  const request = new GetProfileRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getProfile, request);
}

export function getAccount(userId: string): Promise<GetAccountResponse> {
  const request = new GetAccountRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getAccount, request);
}

export function getExtraInfo(userId: string): Promise<GetExtraInfoResponse> {
  const request = new GetExtraInfoRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getExtraInfo, request);
}

export function deletePhoto(
  userId: string,
  reasonContent: string,
  photoIds: string[],
): Promise<DeletePhotoResponse> {
  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new DeletePhotoRequest();
  request.setUserId(userId);
  request.setPhotoIdsList(photoIds);
  request.setReason(reason);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.deletePhoto, request);
}

export function setProfile(
  userId: string,
  reasonContent: string,
  fields: ProfileField[],
): Promise<SetProfileResponse> {
  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new SetProfileRequest();
  request.setUserId(userId);
  request.setReason(reason);
  request.setFieldsList(fields);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.setProfile, request);
}

export function setAccount(
  userId: string,
  reasonContent: string,
  fields: AccountField[],
): Promise<SetAccountResponse> {
  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new SetAccountRequest();
  request.setUserId(userId);
  request.setReason(reason);
  request.setFieldsList(fields);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.setAccount, request);
}

export function sendEmailRecovery(
  userId: string,
  reasonContent: string,
): Promise<SendEmailRecoveryResponse> {
  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new SendEmailRecoveryRequest();
  request.setUserId(userId);
  request.setReason(reason);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.sendEmailRecovery, request);
}

export function setSettings(
  userId: string,
  reasonContent: string,
  fields: SettingsField[],
): Promise<SetSettingsResponse> {
  const reason = new Reason();
  reason.setContent(reasonContent);

  const request = new SetSettingsRequest();
  request.setUserId(userId);
  request.setReason(reason);
  request.setFieldsList(fields);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.setSettings, request);
}

export function setAccountStatus(
  userId: string,
  status: AccountStatus,
  { content, description, categoryList }: Reason.AsObject,
): Promise<SetAccountStatusResponse> {
  const reason = new Reason();
  reason.setContent(content);
  reason.setDescription(description);
  reason.setCategoryList(categoryList);

  const request = new SetAccountStatusRequest();
  request.setUserId(userId);
  request.setReason(reason);
  request.setStatus(status);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.setAccountStatus, request);
}

export function unsubscribePremium(
  userId: string,
  reasonContent: string,
): Promise<UnsubscribePremiumResponse> {
  const request = new UnsubscribePremiumRequest();
  request.setUserId(userId);
  request.setReason(reasonContent);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.unsubscribePremium, request);
}

export function getSubscriptionStatus(userId: string): Promise<GetSubscriptionStatusResponse> {
  const request = new GetSubscriptionStatusRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getSubscriptionStatus, request);
}

export function getAuditLog({
  userId,
  limit,
  searchAfter,
}: GetAuditLogRequest.AsObject): Promise<GetAuditLogResponse> {
  const request = new GetAuditLogRequest();
  request.setUserId(userId);
  request.setLimit(limit);
  request.setSearchAfter(searchAfter);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getAuditLog, request);
}

export function updateAvatar({
  userId,
  photoId,
}: UpdateAvatarRequest.AsObject): Promise<UpdateAvatarResponse> {
  const request = new UpdateAvatarRequest();
  request.setUserId(userId);
  request.setPhotoId(photoId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.updateAvatar, request);
}

export function uploadPhoto({
  userId,
  photoId,
}: UploadPhotoRequest.AsObject): Promise<UploadPhotoResponse> {
  const request = new UploadPhotoRequest();
  request.setUserId(userId);
  request.setPhotoId(photoId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.uploadPhoto, request);
}

export function getOneTimeHash({
  userId,
}: GetOneTimeHashRequest.AsObject): Promise<GetOneTimeHashResponse> {
  const request = new GetOneTimeHashRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getOneTimeHash, request);
}

export function listTags(): Promise<ListTagsResponse> {
  const request = new ListTagsRequest();

  return makeRpcPromisable(usersAPIClient, usersAPIClient.listTags, request);
}

function getTempProfile(userId: string): Promise<GetTempProfileResponse> {
  const request = new GetTempProfileRequest();
  request.setProfileId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.getTempProfile, request);
}

function saveTempProfile(temp: TempProfile.AsObject): Promise<SaveTempProfileResponse> {
  const request = new SaveTempProfileRequest();
  const tempRaw = new TempProfile();
  tempRaw.setProfileId(temp.profileId);
  tempRaw.setName(temp.name);
  tempRaw.setAbout(temp.about);
  tempRaw.setLookingForList(temp.lookingForList);
  tempRaw.setGender(temp.gender);
  tempRaw.setSphereOfWork(temp.sphereOfWork);
  tempRaw.setEducation(temp.education);
  tempRaw.setMatchGender(temp.matchGender);
  tempRaw.setSexualOrientation(temp.sexualOrientation);
  tempRaw.setMaritalStatus(temp.maritalStatus);
  tempRaw.setChildren(temp.children);
  tempRaw.setEyeColor(temp.eyeColor);
  tempRaw.setHairColor(temp.hairColor);
  tempRaw.setReligion(temp.religion);
  tempRaw.setBodyType(temp.bodyType);
  tempRaw.setSport(temp.sport);
  tempRaw.setDrinking(temp.drinking);
  tempRaw.setSmoking(temp.smoking);
  tempRaw.setTagsList(temp.tagsList);

  const heightRaw = new ProfileHeight();
  heightRaw.setValue(temp.height?.value ?? 0);
  heightRaw.setUnits(temp.height?.units ?? 0);
  tempRaw.setHeight(heightRaw);

  const locationRaw = new ProfileLocation();
  locationRaw.setCountry(temp.location?.country ?? '');
  locationRaw.setCity(temp.location?.city ?? '');
  locationRaw.setState(temp.location?.state ?? '');
  locationRaw.setLatitude(temp.location?.latitude ?? 0);
  locationRaw.setLongitude(temp.location?.longitude ?? 0);
  tempRaw.setLocation(locationRaw);

  const photoRawList = temp.photosList.map((photo) => {
    const photoRaw = new ProfilePhoto();
    photoRaw.setId(photo.id);
    photoRaw.setDeclineReason(photo.declineReason);
    photoRaw.setPhotoStatus(photo.photoStatus);
    photoRaw.setTagsList(photo.tagsList);

    return photoRaw;
  });
  tempRaw.setPhotosList(photoRawList);

  const birthdayTime = new Timestamp();
  birthdayTime.setSeconds(temp.birthdayTime?.seconds ?? 0);
  birthdayTime.setNanos(temp.birthdayTime?.nanos ?? 0);
  tempRaw.setBirthdayTime(birthdayTime);

  request.setProfile(tempRaw);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.saveTempProfile, request);
}

function generatePasswordRecoveryLink(
  userId: string,
): Promise<GeneratePasswordRecoveryLinkResponse> {
  const request = new GeneratePasswordRecoveryLinkRequest();
  request.setUserId(userId);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.generatePasswordRecoveryLink, request);
}

function listUsersTimezones(userIdList: string[]): Promise<ListUsersTimezonesResponse> {
  const request = new ListUsersTimezonesRequest();
  request.setUserIdsList(userIdList);

  return makeRpcPromisable(usersAPIClient, usersAPIClient.listUsersTimezones, request);
}

export const usersApi = {
  getTempProfile,
  saveTempProfile,
  generatePasswordRecoveryLink,
  listUsersTimezones,
};
