import * as actions from './keywords-actions';
import * as mutations from './keywords-mutations';
import { state } from './keywords-state';

export const keywordsPersisted = [];

export const keywords = {
  state,
  mutations,
  actions,
};
