import * as actions from './chat-actions';
import * as mutations from './chat-mutations';
import { state } from './chat-state';

export const chatPersisted = [];

export const chat = {
  state,
  mutations,
  actions,
};
