// import { store } from './store';

import { Store } from 'vuex';

import { NotesState } from '@/components/notes/store/notes-state';
import { ChatState } from '@/pages/chat/store/chat-state';
import { KeywordsState } from '@/pages/keywords/store/keywords-state';
import { MemberState } from '@/pages/member/store/member-state';
import { NewProfilesState } from '@/pages/new-profiles/store/new-profiles-state';
import { PaymentsState } from '@/pages/payments/store/payments-state';
import { ReportAbuseState } from '@/pages/report-abuse/store/report-abuse-state';
import { UsersState } from '@/pages/users/store/users-state';
import { ErrorState } from '@/store/modules/error/error-state';

type Commit<T> = {
  <M extends keyof T>(mutation: M, ...payload: T[M] extends never ? [] : [T[M]]): void;
};

export type ActionFunction<A, R> = {
  argumentType: A;
  returnType: R;
};

export interface Action {
  [key: string]: ActionFunction<unknown, unknown>;
}

type Dispatch<T extends Action> = {
  <A extends keyof T>(
    mutation: A,
    ...payload: T[A]['argumentType'] extends never ? [] : [T[A]['argumentType']]
  ): Promise<T[A]['returnType']>;
};

type StoreData = {
  member: MemberState;
  newProfiles: NewProfilesState;
  users: UsersState;
  error: ErrorState;
  keywords: KeywordsState;
  chat: ChatState;
  reportAbuse: ReportAbuseState;
  payments: PaymentsState;
  notes: NotesState;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Mutation {}

let store: Store<StoreData>;

export const commit: Commit<Mutation> = (mutation, payload?) => {
  store.commit(mutation, payload);
};

export const dispatch: Dispatch<Action> = (action, payload?) =>
  store.dispatch(action as string, payload);

export function setStore(s: Store<StoreData>) {
  store = s;
}

export function getStore(): Store<StoreData> {
  return store;
}

export function getState(): StoreData {
  return store.state;
}
