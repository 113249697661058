import { ref } from 'vue';

type ToastStyle = 'success' | 'danger' | 'warning' | 'info';

export type Toast = {
  text: string;
  style?: ToastStyle;
};

export type ToastItem = Toast & { id: number };

const toastList = ref<ToastItem[]>([]);

export function showToast(toast: Toast) {
  if (!toast.style) {
    toast.style = 'success';
  }

  toastList.value.push({
    id: Date.now(),
    ...toast,
  });

  setTimeout(() => {
    toastList.value.shift();
  }, 3000);
}

export function getToastList() {
  return toastList;
}
