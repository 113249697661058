import { createApp } from 'vue';
import VueFinalModal, { VfmOptions } from 'vue-final-modal';

import { setApp } from '@/helpers/app/app';
import { getThemeName, setThemeName } from '@/helpers/theme/theme';
import { chatNotificationHandler } from '@/pages/chat/helpers/chat-websocket/chat-websocket';
import { loadGtag } from '@/services/gtag/gtag';
import { initRealtimeTracking } from '@/services/realtime-tracking/realtime-tracking';
import { initSentry } from '@/services/sentry/sentry';
import { addNotificationHandler } from '@/services/websocket';
import { setStore } from '@/store/store-helper';

import App from './App.vue';
import { router } from './router';
import { i18n, Locale, setLocale } from './services/i18n';
import { store } from './store';

setLocale(Locale.En);

addNotificationHandler(chatNotificationHandler);

initRealtimeTracking();

setThemeName(getThemeName());

export const app = createApp(App);

app.use(store);
setStore(store);

app.use(router);
app.use(i18n);
app.use<VfmOptions>(VueFinalModal, {
  key: 'modalService',
  componentName: 'VueFinalModal',
  dynamicContainerName: 'ModalsContainer',
});
setApp(app.config);
initSentry(app);

app.mount('#app');

loadGtag();
