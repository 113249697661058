import { getCache, setCache } from '@/helpers/persistent-cache';

export type ThemeName = 'dark' | 'light';

const THEME_NAME_KEY = 'theme_name';

export function getThemeName() {
  return (getCache(THEME_NAME_KEY) as ThemeName) ?? 'light';
}

export function setThemeName(themeName: ThemeName) {
  setCache(THEME_NAME_KEY, themeName);
  window.document.documentElement.setAttribute('data-bs-theme', themeName);
}
