import {
  Feature,
  Role,
  Status,
  TeamMember,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';

export type MemberStateMember = TeamMember.AsObject;

export type MemberState = {
  member: MemberStateMember;
  members: Map<string, MemberStateMember>;
  features: Feature[];
};

export const state: MemberState = {
  member: {
    id: '',
    avatarUrl: '',
    email: '',
    fullName: '',
    role: Role.ROLE_INVALID,
    status: Status.STATUS_INVALID,
    invitedBy: '',
    createdTime: {
      nanos: 0,
      seconds: 0,
    },
  },
  features: [],
  members: new Map<string, MemberStateMember>(),
};
