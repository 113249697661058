declare global {
  interface Window {
    __GRPCWEB_DEVTOOLS__: (cl: unknown[]) => void;
  }
}

export const enableDevTools = (cl: unknown[]) => {
  // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-empty-function
  const fn = window.__GRPCWEB_DEVTOOLS__ || (() => {});
  fn(cl);
};
