import { Feature } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';

import { MemberStateMember, MemberState } from '@/pages/member/store/member-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationMemberSetMember: MemberStateMember;
    mutationMemberSetFeatures: Feature[];
    mutationMemberSetMembers: Map<string, MemberStateMember>;
  }
}

export function mutationMemberSetMember(state: MemberState, member: MemberStateMember) {
  state.member = member;
}

export function mutationMemberSetFeatures(state: MemberState, features: Feature[]) {
  state.features = features;
}

export function mutationMemberSetMembers(
  state: MemberState,
  members: Map<string, MemberStateMember>,
) {
  members.forEach((member, id) => state.members.set(id, member));
}
