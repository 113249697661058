import * as actions from './member-actions';
import * as mutations from './member-mutations';
import { state } from './member-state';

export const memberPersisted = [];

export const member = {
  state,
  mutations,
  actions,
};
