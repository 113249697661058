import * as actions from './users-actions';
import * as mutations from './users-mutations';
import { state } from './users-state';

export const usersPersisted = ['users.searchCriteria'];

export const users = {
  state,
  mutations,
  actions,
};
