import { GetProfilesMatchesResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/keywords_api_pb';
import { Matches } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/keywords/v1/match_pb';
import runes from 'runes';
import sanitize from 'sanitize-html';

import { KeywordsStateProfile } from '@/pages/keywords/store/keywords-state';

export const KEYWORDS_LIST_LIMIT = 20;

export function getProfilesFrom(response: GetProfilesMatchesResponse): KeywordsStateProfile[] {
  const profilesMatches: KeywordsStateProfile[] = [];
  response.getProfilesMatchesList().forEach((profileMatch) => {
    profilesMatches.push({
      id: profileMatch.getId(),
      photoId: profileMatch.getPhotoId(),
      gender: profileMatch.getGender(),
      age: profileMatch.getAge(),
      name: profileMatch.getName()?.toObject(),
      about: profileMatch.getAbout()?.toObject(),
      educationalInstitution: profileMatch.getEducationalInstitution()?.toObject(),
      updatedTimeMs: (profileMatch.getUpdatedTime()?.getSeconds() ?? 0) * 1000,
    });
  });

  return profilesMatches;
}

export function highlightMatches(matches: Matches.AsObject): string {
  let result = '';
  const { matchesItemsList, original } = matches;
  const matchListCopy = [...matchesItemsList];
  matchListCopy.sort((i, j) => i.start - j.end);

  let offset = 0;
  matchListCopy.forEach((item) => {
    result += runes.substr(original, offset, item.start - offset);
    result += `<span class='highlight' title='${item.keyword}'>${runes.substr(
      original,
      item.start,
      item.end - item.start,
    )}</span>`;
    offset = item.end;
  });
  result += runes.substr(original, offset);
  return result;
}

export function sanitizeHighlights(highlightedText: string): string {
  return sanitize(highlightedText, {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['title', 'class'],
    },
  });
}
