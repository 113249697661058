import * as actions from './new-profiles-actions';
import * as mutations from './new-profiles-mutations';
import { state } from './new-profiles-state';

export const newProfilesPersisted = [];

export const newProfiles = {
  state,
  mutations,
  actions,
};
