import { ActionContext } from 'vuex';

import { PAYMENTS_LIST_LIMIT } from '@/pages/payments/store/helpers/payments-helper';
import {
  PaymentsState,
  PaymentsStatePayment,
  PaymentsStateReasons,
  PaymentsStateSearchCriteria,
  PaymentsStateSearchStatItem,
} from '@/pages/payments/store/payments-state';
import { listReasons, searchPayments } from '@/services/api/grpc/payments-api/payments-api';
import { ActionFunction, commit } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionPaymentsGetPayments: ActionFunction<
      PaymentsStateSearchCriteria | undefined,
      PaymentsStatePayment[]
    >;
    actionPaymentsGetReasons: ActionFunction<never, PaymentsStateReasons>;
  }
}

export async function actionPaymentsGetPayments(
  { state }: ActionContext<PaymentsState, unknown>,
  criteria?: PaymentsStateSearchCriteria,
): Promise<PaymentsStatePayment[]> {
  // If it's new search ( there are no users yet ) we won't use `searchAfter`.
  const searchAfter = state.payments.length > 0 ? state.searchAfter : '';

  const response = await searchPayments({
    criteria,
    limit: PAYMENTS_LIST_LIMIT,
    searchAfter,
  });

  const payments = response
    .getPaymentsList()
    .map((item) => item.toObject()) as PaymentsStatePayment[];

  const searchStats = response
    .getStatsList()
    .map((item) => item.toObject()) as PaymentsStateSearchStatItem[];

  commit('mutationPaymentsSetSearchStats', searchStats);
  commit('mutationPaymentsAddPayments', payments);
  commit('mutationPaymentsSetSearchAfter', response.getCursor()?.getNext() ?? '');

  return payments;
}

export async function actionPaymentsGetReasons({
  state,
}: ActionContext<PaymentsState, unknown>): Promise<PaymentsStateReasons> {
  const response = await listReasons();

  return response.toObject();
}
