import {
  Action,
  AgeRange,
  Category,
  Gender,
  Locale,
  MessageBlock,
  PaginationRequest,
  Phrase,
  PhraseContent,
  Scenario,
  Scene,
  SceneOptions,
  Selector,
  SplitTest,
  UserPremiumStatus,
  VariantSelector,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gd-dispatcher-api/gdx/automess/v2/scenario_pb';
import {
  BatchSetScenarioPrioritiesRequest,
  BatchSetScenarioPrioritiesResponse,
  BatchSetScenariosActiveRequest,
  BatchSetScenariosActiveResponse,
  DeleteCategoryRequest,
  DeleteCategoryResponse,
  DeletePhrasesRequest,
  DeletePhrasesResponse,
  DeleteScenarioRequest,
  DeleteScenarioResponse,
  GetCategoryRequest,
  GetCategoryResponse,
  ListCategoriesRequest,
  ListCategoriesResponse,
  ListPhrasesRequest,
  ListPhrasesResponse,
  ListScenariosRequest,
  ListScenariosResponse,
  SaveCategoryRequest,
  SaveCategoryResponse,
  SavePhrasesRequest,
  SavePhrasesResponse,
  SaveScenarioRequest,
  SaveScenarioResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gd-dispatcher-api/gdx/automess/v2/scenarios_api_pb';
import { ScenariosServiceClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gd-dispatcher-api/gdx/automess/v2/Scenarios_apiServiceClientPb';
import { Duration } from 'google-protobuf/google/protobuf/duration_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { SceneFilterActive } from '@/pages/chat/components/chat-dispatcher/helpers/type/type';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const scenariosServiceClient = new ScenariosServiceClient(GRPC_ENDPOINT, null, null);

enableDevTools([scenariosServiceClient]);

export const SCENARIO_LIMIT = 20;

export type ListScenariosArg = {
  from: string;
  limit: number;
  filterActive: SceneFilterActive;
};
function listScenarios(arg: ListScenariosArg): Promise<ListScenariosResponse> {
  const request = new ListScenariosRequest();
  const pagination = new PaginationRequest();
  pagination.setFrom(arg.from);
  pagination.setLimit(arg.limit);
  request.setPagination(pagination);

  const filter = new ListScenariosRequest.Filter();
  if (arg.filterActive !== 'all') {
    const isActive = new BoolValue();
    isActive.setValue(arg.filterActive === 'active');
    filter.setIsActive(isActive);
  }
  request.setFilter(filter);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.listScenarios, request);
}

export type ListCategoriesArg = {
  from: string;
  limit: number;
  filterTitle: string;
};
function listCategories(arg: ListCategoriesArg): Promise<ListCategoriesResponse> {
  const request = new ListCategoriesRequest();
  request.setFilterTitle(arg.filterTitle);
  const pagination = new PaginationRequest();
  pagination.setFrom(arg.from);
  pagination.setLimit(arg.limit);
  request.setPagination(pagination);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.listCategories, request);
}

function getCategory(categoryId: string): Promise<GetCategoryResponse> {
  const request = new GetCategoryRequest();
  request.setId(categoryId);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.getCategory, request);
}

function deleteCategory(categoryId: string): Promise<DeleteCategoryResponse> {
  const request = new DeleteCategoryRequest();
  request.setId(categoryId);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.deleteCategory, request);
}

function saveCategory(arg: Category.AsObject): Promise<SaveCategoryResponse> {
  const request = new SaveCategoryRequest();
  const category = new Category();
  category.setId(arg.id);
  category.setTitle(arg.title);
  request.setCategory(category);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.saveCategory, request);
}

function listPhrases(arg: Required<ListPhrasesRequest.AsObject>): Promise<ListPhrasesResponse> {
  const request = new ListPhrasesRequest();
  request.setCategoryId(arg.categoryId);
  request.setPhraseFilter(arg.phraseFilter);
  const pagination = new PaginationRequest();
  pagination.setFrom(arg.pagination.from);
  pagination.setLimit(arg.pagination.limit);
  request.setPagination(pagination);
  request.setExcludeIdsList(arg.excludeIdsList);
  request.setIncludeIdsList(arg.includeIdsList);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.listPhrases, request);
}

function savePhrases(arg: SavePhrasesRequest.AsObject): Promise<SavePhrasesResponse> {
  const request = new SavePhrasesRequest();
  request.setCategoryId(arg.categoryId);
  request.setPhrasesList(
    arg.phrasesList.map((phraseArg) => {
      const phrase = new Phrase();
      phrase.setId(phraseArg.id);
      const content = new PhraseContent();
      if (phraseArg.message?.giftId) {
        content.setGiftId(phraseArg.message.giftId);
      } else {
        content.setText(phraseArg.message?.text ?? '');
      }
      phrase.setMessage(content);
      phrase.setHasPlaceholder(phraseArg.hasPlaceholder);

      return phrase;
    }),
  );

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.savePhrases, request);
}

function deletePhrases(phraseIdList: string[]): Promise<DeletePhrasesResponse> {
  const request = new DeletePhrasesRequest();
  request.setIdsList(phraseIdList);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.deletePhrases, request);
}

function saveScenario(scenario: Scenario.AsObject): Promise<SaveScenarioResponse> {
  const request = new SaveScenarioRequest();
  const scenarioNew = new Scenario();
  scenarioNew.setId(scenario.id);
  scenarioNew.setTitle(scenario.title);
  scenarioNew.setIsActive(scenario.isActive);

  const timeCreated = new Timestamp();
  timeCreated.setSeconds(scenario.createdTime?.seconds ?? 0);
  scenarioNew.setCreatedTime(timeCreated);

  const selector = new Selector();

  if (scenario.selector?.ageRange?.from) {
    const ageRange = new AgeRange();
    ageRange.setFrom(scenario.selector.ageRange.from);
    ageRange.setTo(scenario.selector.ageRange.to);
    selector.setAgeRange(ageRange);
  }

  selector.setGender(scenario.selector?.gender ?? Gender.GENDER_UNSPECIFIED);

  selector.setMessageBlock(
    scenario.selector?.messageBlock ?? MessageBlock.MESSAGE_BLOCK_UNSPECIFIED,
  );

  if (scenario.selector?.splitTest?.testId || scenario.selector?.splitTest?.value) {
    const splitTest = new SplitTest();
    splitTest.setTestId(scenario.selector.splitTest.testId);
    splitTest.setValue(scenario.selector.splitTest.value);
    selector.setSplitTest(splitTest);
  }

  selector.setPremiumStatus(
    scenario.selector?.premiumStatus ?? UserPremiumStatus.USER_PREMIUM_STATUS_UNSPECIFIED,
  );

  selector.setLocale(scenario.selector?.locale ?? Locale.LOCALE_UNSPECIFIED);

  scenarioNew.setSelector(selector);

  scenario.scenesList.forEach((scene) => {
    const sceneNew = new Scene();
    sceneNew.setId(scene.id);

    const actionNext = new Action();
    if (scene.next?.nextScene) {
      const nextScene = new Action.NextScene();
      nextScene.setSceneId(scene.next.nextScene.sceneId);
      const delayDuration = new Duration();
      delayDuration.setSeconds(scene.next.nextScene.delayDuration?.seconds ?? 0);
      delayDuration.setNanos(scene.next.nextScene.delayDuration?.nanos ?? 0);
      nextScene.setDelayDuration(delayDuration);
      actionNext.setNextScene(nextScene);
    } else {
      const theEnd = new Action.TheEnd();
      theEnd.setUnread(scene.next?.theEnd?.unread ?? false);
      actionNext.setTheEnd(theEnd);
    }
    sceneNew.setNext(actionNext);

    const actionReply = new Action();
    if (scene.reply?.nextScene) {
      const nextScene = new Action.NextScene();
      nextScene.setSceneId(scene.reply.nextScene.sceneId);
      const delayDuration = new Duration();
      delayDuration.setSeconds(scene.reply.nextScene.delayDuration?.seconds ?? 0);
      delayDuration.setNanos(scene.reply.nextScene.delayDuration?.nanos ?? 0);
      nextScene.setDelayDuration(delayDuration);
      actionReply.setNextScene(nextScene);
    } else {
      const theEnd = new Action.TheEnd();
      theEnd.setUnread(scene.reply?.theEnd?.unread ?? false);
      actionReply.setTheEnd(theEnd);
    }
    sceneNew.setReply(actionReply);

    const actionFail = new Action();
    if (scene.failed?.nextScene) {
      const nextScene = new Action.NextScene();
      nextScene.setSceneId(scene.failed.nextScene.sceneId);
      const delayDuration = new Duration();
      delayDuration.setSeconds(scene.failed.nextScene.delayDuration?.seconds ?? 0);
      delayDuration.setNanos(scene.failed.nextScene.delayDuration?.nanos ?? 0);
      nextScene.setDelayDuration(delayDuration);
      actionFail.setNextScene(nextScene);
    } else {
      const theEnd = new Action.TheEnd();
      theEnd.setUnread(scene.failed?.theEnd?.unread ?? false);
      actionFail.setTheEnd(theEnd);
    }
    sceneNew.setFailed(actionFail);

    const actionSkip = new Action();
    if (scene.skipped?.nextScene) {
      const nextScene = new Action.NextScene();
      nextScene.setSceneId(scene.skipped.nextScene.sceneId);
      const delayDuration = new Duration();
      delayDuration.setSeconds(scene.skipped.nextScene.delayDuration?.seconds ?? 0);
      delayDuration.setNanos(scene.skipped.nextScene.delayDuration?.nanos ?? 0);
      nextScene.setDelayDuration(delayDuration);
      actionSkip.setNextScene(nextScene);
    } else {
      const theEnd = new Action.TheEnd();
      theEnd.setUnread(scene.skipped?.theEnd?.unread ?? false);
      actionSkip.setTheEnd(theEnd);
    }
    sceneNew.setSkipped(actionSkip);

    const categoryMessageList: Scene.CategoryMessages[] = [];
    scene.categoriesList.forEach((category) => {
      const messageNew = new Scene.CategoryMessages();
      const categoryNew = new Category();
      categoryNew.setId(category.category?.id ?? '');
      categoryNew.setTitle(category.category?.title ?? '');
      messageNew.setCategory(categoryNew);

      messageNew.setExcludePhraseIdsList(category.excludePhraseIdsList);
      messageNew.setIncludePhraseIdsList(category.includePhraseIdsList);
      messageNew.setFiltersList(category.filtersList);

      categoryMessageList.push(messageNew);
    });
    sceneNew.setCategoriesList(categoryMessageList);

    const options = new SceneOptions();
    options.setSkipChance(scene.options?.skipChance ?? 0);
    const variantSelector = new VariantSelector();
    if (scene.options?.variantSelector?.random) {
      const random = new VariantSelector.Random();
      variantSelector.setRandom(random);
    } else {
      const roundRobin = new VariantSelector.RoundRobin();
      variantSelector.setRoundRobin(roundRobin);
    }
    options.setVariantSelector(variantSelector);
    sceneNew.setOptions(options);

    scenarioNew.addScenes(sceneNew);
  });

  request.setScenario(scenarioNew);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.saveScenario, request);
}

function deleteScenario(scenarioId: string): Promise<DeleteScenarioResponse> {
  const request = new DeleteScenarioRequest();
  request.setId(scenarioId);

  return makeRpcPromisable(scenariosServiceClient, scenariosServiceClient.deleteScenario, request);
}

function batchSetScenariosActive(
  arg: BatchSetScenariosActiveRequest.AsObject,
): Promise<BatchSetScenariosActiveResponse> {
  const request = new BatchSetScenariosActiveRequest();
  request.setIsActive(arg.isActive);
  request.setIdsList(arg.idsList);

  return makeRpcPromisable(
    scenariosServiceClient,
    scenariosServiceClient.batchSetScenariosActive,
    request,
  );
}

function batchSetScenarioPriorities(
  scenarioIdList: string[],
): Promise<BatchSetScenarioPrioritiesResponse> {
  const request = new BatchSetScenarioPrioritiesRequest();
  request.setScenariosIdsList(scenarioIdList);

  return makeRpcPromisable(
    scenariosServiceClient,
    scenariosServiceClient.batchSetScenarioPriorities,
    request,
  );
}

export const scenariosApi = {
  listScenarios,
  listCategories,
  getCategory,
  deleteCategory,
  saveCategory,
  listPhrases,
  savePhrases,
  deletePhrases,
  saveScenario,
  deleteScenario,
  batchSetScenariosActive,
  batchSetScenarioPriorities,
};
