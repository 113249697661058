import { Channel } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';

import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import SessionFinishModal from '@/components/session/session-finish-modal/SessionFinishModal.vue';
import {
  endSession,
  keepAliveSession,
  startSession,
} from '@/services/api/grpc/chatters-api/chatters-api';
import useModal from '@/services/modal/modal';
import { store } from '@/store';

const { showModal, hideModal } = useModal({
  sessionSpinner: {
    component: ModalSpinner,
  },
});

const KEEP_ALIVE_PING_INTERVAL_MS = 10000;

let sessionKeepAliveIntervalId: number | null = null;
export function startSessionKeepAlive() {
  if (sessionKeepAliveIntervalId !== null) {
    return;
  }

  sessionKeepAliveIntervalId = window.setInterval(() => {
    if (isSessionStarted()) {
      keepAliveSession();
    } else {
      stopSessionKeepAlive();
    }
  }, KEEP_ALIVE_PING_INTERVAL_MS);
}

export function stopSessionKeepAlive() {
  if (sessionKeepAliveIntervalId !== null) {
    window.clearInterval(sessionKeepAliveIntervalId);
    sessionKeepAliveIntervalId = null;
  }
}

export function hideSessionSpinner(): void {
  hideModal('sessionSpinner');
}

export function isSessionStarted(): boolean {
  return store.state.chat.session !== undefined;
}

// export function subscribeSessionChannel() {
//   const memberId = store.state.member.member.id;
//   setUserChannelBoundary('#' + memberId);
//   websocketChannelResubscribe();
// }

function start(channels: Channel[]) {
  showModal('sessionSpinner');
  startSession(channels);
}

function stop(isForced = false) {
  if (!isForced) {
    const { showModal } = useModal({
      sessionFinish: {
        component: SessionFinishModal,
      },
    });

    showModal('sessionFinish');
  } else {
    stopForced();
  }
}

// Stop session without checking unread count.
function stopForced() {
  showModal('sessionSpinner');
  endSession();
}

export function useSession() {
  return {
    start,
    stop,
  };
}
