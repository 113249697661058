import { ErrorState, ErrorStateMessageItemType } from '@/store/modules/error/error-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationErrorAddMessageItem: ErrorStateMessageItemType;
  }
}

export function mutationErrorAddMessageItem(state: ErrorState, type: ErrorStateMessageItemType) {
  state.errors.push({
    isVisible: true,
    type,
  });
}
