import { ListReasonsResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/payments/v1/payments_api_pb';
import {
  FraudScoreFilter,
  Payment,
  PaymentStatus,
  SearchPaymentsStatItem,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/payments/v1/payments_pb';

export type PaymentsStatePayment = Required<Payment.AsObject>;

export type PaymentsStateReasons = ListReasonsResponse.AsObject;

export type PaymentsStateSearchStatItem = SearchPaymentsStatItem.AsObject;

export type PaymentsStateSearchCriteria = {
  filter?: string;
  status?: PaymentStatus;
  reasons?: string[];
  fraudScore?: FraudScoreFilter;
  cardCountry?: string;
  onlyVpn?: boolean;
  orderDateRange?: {
    fromTimeMs?: number;
    toTimeMs?: number;
  };
  orderDateUpdatedRange?: {
    fromTimeMs?: number;
    toTimeMs?: number;
  };
};

export type PaymentsState = {
  payments: PaymentsStatePayment[];
  searchAfter: string;
  searchCriteria: PaymentsStateSearchCriteria;
  searchStats: PaymentsStateSearchStatItem[];
};

export const state: PaymentsState = {
  payments: [],
  searchAfter: '',
  searchCriteria: {},
  searchStats: [],
};
