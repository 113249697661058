import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  message: string;
  relogin: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'There is some error we caught.',
    message: 'Please try to relogin - it may fix your issue.',
    relogin: 'relogin',
  },
};

export { messages };
