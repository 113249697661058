import {
  ListNotesRequest,
  SaveDialogNoteRequest,
  SaveProfileNoteRequest,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_api_pb';
import {
  Identity,
  Note,
  NoteType,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_pb';
import { ActionContext } from 'vuex';

import { NotesState } from '@/components/notes/store/notes-state';
import {
  deleteNote,
  deleteProfileNote,
  fetchNotes,
  getNotesCount,
} from '@/services/api/grpc/notes-api/notes-api';
import { ActionFunction, commit, dispatch } from '@/store/store-helper';

import { saveNote, saveProfileNote } from './helpers/action-helper/action-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionNotesFetch: ActionFunction<
      ListNotesRequest.AsObject & { noteType: NoteType },
      Note.AsObject[]
    >;
    actionNotesGetCount: ActionFunction<{ dialogId: string; userId?: string }, never>;
    actionNotesCreate: ActionFunction<SaveDialogNoteRequest.AsObject, never>;
    actionNotesUpdate: ActionFunction<SaveDialogNoteRequest.AsObject, never>;
    actionNotesDelete: ActionFunction<string, never>;
    actionProfileNotesFetch: ActionFunction<ListNotesRequest.AsObject, Note.AsObject[]>;
    actionProfileNotesGetCount: ActionFunction<{ userId?: string }, never>;
    actionProfileNotesCreate: ActionFunction<SaveProfileNoteRequest.AsObject, never>;
    actionProfileNotesUpdate: ActionFunction<SaveProfileNoteRequest.AsObject, never>;
    actionProfileNotesDelete: ActionFunction<string, never>;
  }
}

export async function actionNotesFetch(
  { state }: ActionContext<NotesState, unknown>,
  params: ListNotesRequest.AsObject & { noteType: NoteType },
): Promise<Note.AsObject[] | undefined> {
  try {
    const request = await fetchNotes(params);
    const response = request.toObject();
    const notes = response?.notesList;
    const totalPages = response?.pagination?.totalPages;

    if (params.noteType === NoteType.NOTE_TYPE_DIALOG) {
      commit('mutationNotesAdd', { notes, totalPages });
    } else {
      commit('mutationProfileNotesAdd', { notes, totalPages });
    }

    return notes;
  } catch (error) {
    console.warn(error);
  }
}

export async function actionNotesGetCount(
  { state }: ActionContext<NotesState, unknown>,
  identity: Identity.AsObject,
): Promise<void> {
  try {
    const response = await getNotesCount(identity);
    commit('mutationNotesAddNotesCount', response.toObject());
  } catch (error) {
    console.warn(error);
  }
}

export async function actionNotesCreate(
  { state }: ActionContext<NotesState, unknown>,
  payload: SaveDialogNoteRequest.AsObject,
): Promise<void> {
  const note = await saveNote(payload);

  await dispatch('actionNotesGetCount', { dialogId: payload.dialogId });
  commit('mutationNotesAdd', { notes: [note as Note.AsObject] });
}

export async function actionNotesUpdate(
  { state }: ActionContext<NotesState, unknown>,
  payload: SaveDialogNoteRequest.AsObject,
): Promise<void> {
  const note = await saveNote(payload);

  commit('mutationNotesUpdate', note as Note.AsObject);
}

export async function actionNotesDelete(
  { state }: ActionContext<NotesState, unknown>,
  id: string,
): Promise<void> {
  try {
    await deleteNote(id);
  } catch (erorr) {
    console.warn(erorr);
  }

  commit('mutationNotesAddNotesCount', { count: state.count - 1 });
  commit('mutationNotesDelete', id);
}

export async function actionProfileNotesCreate(
  { state }: ActionContext<NotesState, unknown>,
  payload: SaveProfileNoteRequest.AsObject,
): Promise<void> {
  const note = await saveProfileNote(payload);

  commit('mutationProfileNotesAdd', { notes: [note as Note.AsObject] });
}

export async function actionProfileNotesUpdate(
  { state }: ActionContext<NotesState, unknown>,
  payload: SaveProfileNoteRequest.AsObject,
): Promise<void> {
  const note = await saveProfileNote(payload);

  commit('mutationProfileNotesUpdate', note as Note.AsObject);
}

export async function actionProfileNotesDelete(
  { state }: ActionContext<NotesState, unknown>,
  id: string,
): Promise<void> {
  try {
    await deleteProfileNote(id);
  } catch (erorr) {
    console.warn(erorr);
  }

  commit('mutationProfileNotesAddNotesCount', { count: state.count - 1 });
  commit('mutationProfileNotesDelete', id);
}
