import { I18n } from '@/services/i18n';

type Message = {
  youAreInactive: string;
  sessionWillAutomaticallyStop: string;
  endSession: string;
  continueSession: string;
};

const messages: I18n<Message> = {
  en: {
    youAreInactive: 'You’re inactive.',
    sessionWillAutomaticallyStop:
      "You haven't sent any messages recently. Please continue chatting, or the session will be automatically terminated in: ",
    endSession: 'End session',
    continueSession: 'Continue chatting to keep the session.',
  },
};

export { messages };
