import {
  Role,
  Status,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';
import {
  ProfileBodyType,
  ProfileChildren,
  ProfileDrinking,
  ProfileEducation,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileHeightUnits,
  ProfileMaritalStatus,
  ProfileReligion,
  ProfileSexualOrientation,
  ProfileSmoking,
  ProfileSphereOfWork,
  ProfileSport,
  VerifiedBy,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/profile_pb';
import {
  GetAccountResponse,
  GetAuditLogResponse,
  GetBillingHistoryResponse,
  GetBillingResponse,
  GetExtraInfoResponse,
  GetProfileResponse,
  GetSubscriptionStatusResponse,
  SearchUserResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_api_pb';
import {
  AuditLogItem,
  Reason,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_pb';
import { Subscription } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/gateway/web/payment/v2/subscription_pb';

import {
  UsersStateAccount,
  UsersStateBilling,
  UsersStateBillingHistoryItem,
  UsersStateExtraInfo,
  UsersStateLogItem,
  UsersStateLogItemAction,
  UsersStateLogItemFields,
  UsersStateLogItemPhotos,
  UsersStateProfile,
  UsersStateSubscription,
  UsersStateSubscriptionState,
  UsersStateUser,
  UsersStateUserTag,
} from '@/pages/users/store/users-state';

import ExtraInfoCase = AuditLogItem.ExtraInfoCase;
import StateCase = Subscription.StateCase;

type ExtraInfoRawJson = {
  landing_url: string;
};

export const USERS_LIST_LIMIT = 50;
export const BILLING_HISTORY_LIMIT = 50;
export const AUDIT_LOG_LIMIT = 50;
export const PROFILE_MAX_PHOTOS_COUNT = 6;

export function parseExtraInfoRawJson(rawJson: string): ExtraInfoRawJson {
  return JSON.parse(rawJson);
}

export function getUsersFrom(response: SearchUserResponse): UsersStateUser[] {
  const users: UsersStateUser[] = [];
  response.getSearchUsersList().forEach((user) => {
    users.push({
      id: user.getId(),
      email: user.getEmail(),
      status: user.getStatus(),
      photosList: user?.getPhotosList().map((photo) => photo.toObject()) ?? [],
      location: user?.getLocation()?.toObject() ?? {
        city: '',
        country: '',
        state: '',
        latitude: 0,
        longitude: 0,
      },
      name: user?.getName() ?? '',
      createdTimeMs: (user.getCreatedTime()?.getSeconds() ?? 0) * 1000,
      tagsList: user.getTagsList() as UsersStateUserTag[],
      registrationIp: user.getRegistrationIp(),
      fraudScorePayment: user.getFraudScorePayment(),
    });
  });

  return users;
}

export function getBillingFrom(response: GetBillingResponse): UsersStateBilling {
  return {
    creditsCount: response.getCreditsCount(),
    creditsSpentCount: response.getSpentCredits(),
    premiumEndsTimeMs: response.getPremium()?.getEndsTime()?.getSeconds() ?? 0,
    isPremiumActive: response.getPremium()?.getIsActive() ?? false,
  };
}

export function getBillingHistoryFrom(
  response: GetBillingHistoryResponse,
): UsersStateBillingHistoryItem[] {
  const history: UsersStateBillingHistoryItem[] = [];
  response.getHistoryItemsList().forEach((item) => {
    history.push({
      id: item.getId(),
      type: item.getType(),
      title: item.getTitle(),
      reasonContent: item.getReason()?.getContent() ?? '',
      authorName: item.getAuthor()?.getFullName() ?? '',
      changeCreditsCount: item.getChangeCreditsCount(),
      resultCreditsCount: item.getResultCreditsCount(),
      createdTimeMs: (item.getCreatedTime()?.getSeconds() ?? 0) * 1000,
    });
  });

  return history;
}

export function getProfileFrom(response: GetProfileResponse): UsersStateProfile {
  const profile = response.getProfile();

  return {
    currency: profile?.getCurrency() ?? 'USD',
    gender: profile?.getGender() ?? ProfileGender.PROFILE_GENDER_INVALID,
    about: profile?.getAbout() ?? '',
    sphereOfWork: profile?.getSphereOfWork() ?? ProfileSphereOfWork.PROFILE_SPHERE_OF_WORK_INVALID,
    position: profile?.getPosition() ?? '',
    interestsList: profile?.getInterestsList() ?? [],
    birthPlace: profile?.getBirthPlace() ?? '',
    education: profile?.getEducation() ?? ProfileEducation.PROFILE_EDUCATION_INVALID,
    religion: profile?.getReligion() ?? ProfileReligion.PROFILE_RELIGION_INVALID,
    sexualOrientation:
      profile?.getSexualOrientation() ??
      ProfileSexualOrientation.PROFILE_SEXUAL_ORIENTATION_INVALID,
    maritalStatus:
      profile?.getMaritalStatus() ?? ProfileMaritalStatus.PROFILE_MARITAL_STATUS_INVALID,
    ethnicity: profile?.getEthnicity() ?? ProfileEthnicity.PROFILE_ETHNICITY_INVALID,
    height: profile?.getHeight()?.toObject() ?? {
      value: 0,
      units: ProfileHeightUnits.PROFILE_HEIGHT_UNITS_INVALID,
    },
    bodyType: profile?.getBodyType() ?? ProfileBodyType.PROFILE_BODY_TYPE_INVALID,
    hairColor: profile?.getHairColor() ?? ProfileHairColor.PROFILE_HAIR_COLOR_INVALID,
    children: profile?.getChildren() ?? ProfileChildren.PROFILE_CHILDREN_INVALID,
    drinking: profile?.getDrinking() ?? ProfileDrinking.PROFILE_DRINKING_INVALID,
    smoking: profile?.getSmoking() ?? ProfileSmoking.PROFILE_SMOKING_INVALID,
    location: profile?.getLocation()?.toObject() ?? {
      city: '',
      country: '',
      state: '',
      latitude: 0,
      longitude: 0,
    },
    isLocationUpdateAllowed: profile?.getIsLocationUpdateAllowed() ?? false,
    isLocationDetectionAllowed: profile?.getIsLocationDetectionAllowed() ?? false,
    photosList: profile?.getPhotosList().map((photo) => photo.toObject()) ?? [],
    name: profile?.getName() ?? '',
    age: profile?.getAge() ?? 0,
    matchGender: profile?.getMatchGender() ?? ProfileGender.PROFILE_GENDER_INVALID,
    lookingForList: profile?.getLookingForList() ?? [],
    userId: profile?.getUserId() ?? '',
    sport: profile?.getSport() ?? ProfileSport.PROFILE_SPORT_INVALID,
    educationalInstitution: profile?.getEducationalInstitution() ?? '',
    eyeColor: profile?.getEyeColor() ?? ProfileEyeColor.PROFILE_EYE_COLOR_INVALID,
    rawJson: profile?.getRawJson() ?? '',
    timezone: {
      timezone: profile?.getTimezone()?.getTimezone() ?? '',
      offsetMinutes: profile?.getTimezone()?.getOffsetMinutes() ?? 0,
    },
    verifiedByList: profile?.getVerifiedByList() ?? [VerifiedBy.VERIFIED_BY_INVALID],
  };
}

export function getAccountFrom(response: GetAccountResponse): UsersStateAccount {
  const settings = response.getSettings();

  return {
    userId: response.getUserId(),
    name: response.getName(),
    gender: response.getGender(),
    birthday: {
      day: response.getBirthday()?.getDay() ?? 0,
      month: (response.getBirthday()?.getMonth() ?? 1) - 1,
      year: response.getBirthday()?.getYear() ?? 0,
    },
    email: response.getEmail(),
    isEmailVerified: response.getIsEmailVerified(),
    settings: {
      isOnlineStatusHidden: settings?.getIsOnlineStatusHidden() ?? false,
      isProfileHiddenInSearch: settings?.getIsProfileHiddenInSearch() ?? false,
      arePhotosBlurred: settings?.getArePhotosBlurred() ?? false,
      isMyActivityHidden: settings?.getIsMyActivityHidden() ?? false,
      areEmailNotificationsEnabled: settings?.getAreEmailNotificationsEnabled() ?? false,
      areWebPushNotificationsEnabled: settings?.getAreWebPushNotificationsEnabled() ?? false,
      interfaceLanguage: settings?.getInterfaceLanguage() ?? 'en',
      disabledPaymentMethodsList: settings?.getDisabledPaymentMethodsList() ?? [],
    },
    status: response.getStatus(),
    isTest: response.getIsTest(),
    reason: response.getReason()?.toObject() as Reason.AsObject,
    isSusFraud: response.getIsSusFraud(),
    age: response.getAge(),
    isFraud: response.getIsFraud(),
    isScam: response.getIsScam(),
    isOrganic: response.getIsOrganic(),
    isDuplicate: response.getIsDuplicate(),
  };
}

export function getSubscriptionFrom(
  response: GetSubscriptionStatusResponse,
): UsersStateSubscription {
  const status = response.getStatus();
  const result = status?.toObject() as UsersStateSubscription;

  if (status?.getStateCase() === StateCase.ACTIVE) {
    result.state = status?.getActive()?.toObject() as UsersStateSubscriptionState;
    result.state.type = 'active';
  } else if (status?.getStateCase() === StateCase.PENDING) {
    result.state = status?.getPending()?.toObject() as UsersStateSubscriptionState;
    result.state.type = 'pending';
  } else if (status?.getStateCase() === StateCase.SUSPENDED) {
    result.state = status?.getSuspended()?.toObject() as UsersStateSubscriptionState;
    result.state.type = 'suspended';
  } else if (status?.getStateCase() === StateCase.INACTIVE) {
    result.state = status?.getInactive()?.toObject() as UsersStateSubscriptionState;
    result.state.type = 'inactive';
  } else {
    result.state = {
      type: 'inactive',
    };
  }

  return result;
}

export function getLogItemsFrom(response: GetAuditLogResponse): UsersStateLogItem[] {
  const items: UsersStateLogItem[] = [];
  response.getLogList().forEach((item) => {
    let photos: UsersStateLogItemPhotos | undefined;
    let fields: UsersStateLogItemFields | undefined;

    if (item.getExtraInfoCase() === ExtraInfoCase.PHOTOS) {
      photos = {
        photoIdsList: item.getPhotos()?.getPhotoIdsList() ?? [],
        outcome: item.getPhotos()?.getOutcome() ?? 0,
        isTop: item.getPhotos()?.getIsTop() ?? false,
      };
    } else if (item.getExtraInfoCase() === ExtraInfoCase.FIELDS) {
      fields =
        item
          .getFields()
          ?.getFieldsList()
          ?.map((fieldItem) => fieldItem.toObject()) ?? [];
    }

    items.push({
      action: item.getAction().replaceAll('.', '_') as UsersStateLogItemAction,
      author: {
        avatarUrl: item.getAuthor()?.getAvatarUrl() ?? '',
        email: item.getAuthor()?.getEmail() ?? '',
        id: item.getAuthor()?.getId() ?? '',
        fullName: item.getAuthor()?.getFullName() ?? '',
        role: item.getAuthor()?.getRole() ?? Role.ROLE_INVALID,
        status: item.getAuthor()?.getStatus() ?? Status.STATUS_INVALID,
        invitedBy: item.getAuthor()?.getInvitedBy() ?? '',
        createdTime: {
          nanos: item.getAuthor()?.getCreatedTime()?.getNanos() ?? 0,
          seconds: item.getAuthor()?.getCreatedTime()?.getSeconds() ?? 0,
        },
      },
      createdTimeMs: (item.getCreatedTime()?.getSeconds() ?? 0) * 1000,
      reasonContent: item.getReason()?.getContent() ?? '',
      photos,
      fields,
    });
  });

  return items;
}

export function getExtraInfoFrom(response: GetExtraInfoResponse): UsersStateExtraInfo {
  return {
    rawJson: response.getRawJson(),
    regIpInfo: response.getRegIpInfo()?.toObject() ?? {
      ip: '',
      isDefault: true,
      isHosting: false,
      providerMask: '',
      provider: '',
      country: '',
    },
    lastIpInfo: response.getLastIpInfo()?.toObject() ?? {
      ip: '',
      isDefault: true,
      isHosting: false,
      providerMask: '',
      provider: '',
      country: '',
    },
  };
}
