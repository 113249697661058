import { reactive } from 'vue';

import { getToastList } from '@/components/toaster/helpers/toaster';

export function useSelf() {
  // Ref -----------------------------------------------------------------------
  const toastList = getToastList();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function init() {
    //
  }

  return reactive({
    toastList,
    init,
  });
}
