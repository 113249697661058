import {
  ListSessionChannelsPresetsRequest,
  ListSessionChannelsPresetsResponse,
  ListSessionsRequest,
  ListSessionsResponse,
  SetSessionChannelsPresetRequest,
  SetSessionChannelsPresetResponse,
  TerminateSessionRequest,
  TerminateSessionResponse,
  ListLocalizationsRequest,
  ListLocalizationsResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_management_api_pb';
import { ChattersManagementAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/Chatters_management_apiServiceClientPb';
import { SessionChannelPreset } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const chattersManagementAPIClient = new ChattersManagementAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([chattersManagementAPIClient]);

export function listSessions(limit: number, afterPosition: string): Promise<ListSessionsResponse> {
  const cursor = new CursorPaginationRequest();
  cursor.setLimit(limit);
  cursor.setAfterPosition(afterPosition);

  const request = new ListSessionsRequest();
  request.setCursor(cursor);

  return makeRpcPromisable(
    chattersManagementAPIClient,
    chattersManagementAPIClient.listSessions,
    request,
  );
}

export function terminateSession(teamMemberId: string): Promise<TerminateSessionResponse> {
  const request = new TerminateSessionRequest();
  request.setTeamMemberId(teamMemberId);

  return makeRpcPromisable(
    chattersManagementAPIClient,
    chattersManagementAPIClient.terminateSession,
    request,
  );
}

export function listSessionChannelsPresets(
  filterEmail: string,
): Promise<ListSessionChannelsPresetsResponse> {
  const request = new ListSessionChannelsPresetsRequest();
  request.setFilter(filterEmail);

  const cursor = new CursorPaginationRequest();
  cursor.setAfterPosition('');
  cursor.setLimit(200);
  request.setCursor(cursor);

  return makeRpcPromisable(
    chattersManagementAPIClient,
    chattersManagementAPIClient.listSessionChannelsPresets,
    request,
  );
}

export function setSessionChannelsPreset(
  presetArg: SessionChannelPreset.AsObject,
): Promise<SetSessionChannelsPresetResponse> {
  const request = new SetSessionChannelsPresetRequest();

  const preset = new SessionChannelPreset();
  preset.setTeamMemberId(presetArg.teamMemberId);
  preset.setTeamMemberEmail(presetArg.teamMemberEmail);
  preset.setChannelsList(presetArg.channelsList);
  preset.setLocalesList(presetArg.localesList);

  request.setPreset(preset);

  return makeRpcPromisable(
    chattersManagementAPIClient,
    chattersManagementAPIClient.setSessionChannelsPreset,
    request,
  );
}

export function listLocalizations(): Promise<ListLocalizationsResponse> {
  const request = new ListLocalizationsRequest();

  return makeRpcPromisable(
    chattersManagementAPIClient,
    chattersManagementAPIClient.listLocalizations,
    request,
  );
}
