import { AppConfig } from 'vue';

import { getApp } from '@/helpers/app/app';

import DynamicModalContainer from './components/DynamicModalContainer.vue';
import { Bind, ModalComponent } from './types';

let openedModals: unknown[] = [];

export default function useModal<K extends keyof T, T>(modalComponents: {
  [P in K]: ModalComponent & { bind?: Bind<P> };
}) {
  const modals = modalComponents;

  Object.keys(modals).forEach((k) => {
    const modal = modals[k as K];

    if (!modal.bind) {
      modal.bind = {};
    }

    // Copy `props` to `bind` param
    if (modal.props) {
      modal.bind = { ...modal.bind, ...modal.props };
    }

    // modal.bind.name = k;
  });

  function removeModalFromOpened(name: K) {
    const modalIndex = openedModals.indexOf(name);

    if (modalIndex !== -1) {
      openedModals.splice(modalIndex, 1);
    }
  }

  function showModal(name: K): void {
    if (openedModals.includes(name)) {
      return;
    }

    openedModals.push(name);

    const { component, bind = {}, on = {} } = modals[name];
    const app: AppConfig = getApp();

    const slots = {
      default: {
        component,
        bind,
        on: {
          ...on,
          close: (...args: unknown[]) => {
            hideModal(name);

            // Pass `close` event upper
            if (on.close) {
              (on.close as (...args: unknown[]) => void)(...args);
            }
          },
        },
      },
    };

    try {
      const classes = bind.classes ?? [];

      return app.globalProperties.modalService.show({
        component: DynamicModalContainer,
        bind: {
          ...bind,
          classes: [...classes],
          name,
          closed: () => {
            removeModalFromOpened(name);
          },
        },
        on,
        slots,
      });
    } catch (error) {
      console.warn(error);
    }
  }

  function hideModal(name: K): void {
    const app: AppConfig = getApp();

    removeModalFromOpened(name);
    try {
      app.globalProperties.modalService.hide(name);
    } catch (error) {
      console.warn(error);
    }
  }

  function hideLastModal(): void {
    if (openedModals.length >= 1) {
      // @ts-expect-error: any
      hideModal(openedModals[openedModals.length - 1]);
    }

    openedModals.pop();
  }

  function isModalVisible(name: K): boolean {
    const app: AppConfig = getApp();
    try {
      return app.globalProperties.modalService.get(name).length > 0;
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  return { showModal, hideModal, hideLastModal, isModalVisible };
}

export function hideAllModals(): void {
  const app: AppConfig = getApp();

  openedModals = [];

  try {
    app.globalProperties.modalService.hideAll();
  } catch (error) {
    console.warn(error);
  }
}
