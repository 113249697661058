import { Session } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { computed, reactive, Ref, ref } from 'vue';

import { useSession } from '@/hooks/use-session/use-session';
import { useTick } from '@/hooks/use-tick/use-tick';

dayjs.extend(duration);

const BREAK_DURATION_MAX_MS = 1000 * 60 * 30;

type UseSelfArg = {
  session: Ref<Session.AsObject>;
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const breakTotalMs = ref((arg.session.value.totalBreakDuration?.seconds ?? 0) * 1000);
  const breakCurrentMs = ref((arg.session.value.currentBreakDuration?.seconds ?? 0) * 1000);

  // Hook ----------------------------------------------------------------------
  const tick = reactive(useTick({ value: 0, intervalMs: 1000 }));
  const session = useSession();

  // Computed ------------------------------------------------------------------
  const breakTotalLeftMs = computed(() => {
    return BREAK_DURATION_MAX_MS - (breakTotalMs.value + 1000 * tick.value);
  });

  const breakTotalLeftPercent = computed(() => {
    return Math.round((breakTotalLeftMs.value / BREAK_DURATION_MAX_MS) * 100);
  });

  const breakTotalLeftFormatted = computed(() => {
    if (breakTotalLeftMs.value <= 0) {
      return '00:00';
    }

    return dayjs.duration(breakTotalLeftMs.value).format('mm:ss');
  });

  const breakTotalOverdueFormatted = computed(() => {
    const overdueMs = breakTotalMs.value + 1000 * tick.value - BREAK_DURATION_MAX_MS;

    return dayjs.duration(overdueMs).format('mm:ss');
  });

  const breakCurrentFormatted = computed(() => {
    return dayjs.duration(breakCurrentMs.value + tick.value * 1000).format('mm:ss');
  });

  // Method --------------------------------------------------------------------

  function init() {
    tick.start();
  }

  function destroy() {
    tick.stop();
  }

  return {
    session,
    breakTotalLeftMs,
    breakTotalLeftPercent,
    breakTotalLeftFormatted,
    breakTotalOverdueFormatted,
    breakCurrentFormatted,
    init,
    destroy,
  };
}
