import { Settings } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/account_pb';
import { PhotoStatus } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/profile_pb';

import {
  UsersState,
  UsersStateAccount,
  UsersStateBilling,
  UsersStateBillingHistoryItem,
  UsersStateExtraInfo,
  UsersStateLogItem,
  UsersStateProfile,
  UsersStateSearchCriteria,
  UsersStateSubscription,
  UsersStateUser,
} from '@/pages/users/store/users-state';

declare module '@/store/store-helper' {
  interface Mutation {
    mutationUsersAddUsers: UsersStateUser[];
    mutationUsersSetUsersSearchAfter: string;
    mutationUsersSetSearchCriteria: UsersStateSearchCriteria;
    mutationUsersClearUsers: never;
    mutationUsersSetBilling: UsersStateBilling;
    mutationUsersAddBillingHistoryItems: UsersStateBillingHistoryItem[];
    mutationUsersSetBillingHistorySearchAfter: string;
    mutationUsersClearBillingHistory: never;
    mutationUsersSetProfile: UsersStateProfile;
    mutationUsersSetProfilePhotoStatus: MutationUsersSetProfilePhotoStatus;
    mutationUsersSetAccount: UsersStateAccount;
    mutationUsersProfileDeletePhotos: string[];
    mutationUsersProfileUploadPhoto: string;
    mutationUsersProfileUpdateAvatar: string;
    mutationUsersSetProfileField: Partial<UsersStateProfile>;
    mutationUsersSetAccountField: Partial<UsersStateAccount>;
    mutationUsersSetSettingsField: Partial<Settings.AsObject>;
    mutationUsersSetSubscription: UsersStateSubscription;
    mutationUsersDeactivateSubscription: never;
    mutationUsersAddLogItems: UsersStateLogItem[];
    mutationUsersSetLogSearchAfter: string;
    mutationUsersClearLogItems: never;
    mutationUsersSetExtraInfo: UsersStateExtraInfo;
  }
}

type MutationUsersSetProfilePhotoStatus = {
  photoId: string;
  photoStatus: PhotoStatus;
};

export function mutationUsersAddUsers(state: UsersState, users: UsersStateUser[]) {
  state.users.push(...users);
}

export function mutationUsersSetUsersSearchAfter(state: UsersState, searchAfter: string) {
  state.searchUsersAfter = searchAfter;
}

export function mutationUsersSetSearchCriteria(
  state: UsersState,
  searchCriteria: UsersStateSearchCriteria,
) {
  state.searchCriteria = searchCriteria;
}

export function mutationUsersClearUsers(state: UsersState) {
  state.users = [];
}

export function mutationUsersSetBilling(state: UsersState, billing: UsersStateBilling) {
  state.billing = billing;
}

export function mutationUsersAddBillingHistoryItems(
  state: UsersState,
  history: UsersStateBillingHistoryItem[],
) {
  state.billingHistory.push(...history);
}

export function mutationUsersSetBillingHistorySearchAfter(state: UsersState, searchAfter: string) {
  state.searchBillingHistoryAfter = searchAfter;
}

export function mutationUsersClearBillingHistory(state: UsersState) {
  state.billingHistory = [];
}

export function mutationUsersSetProfile(state: UsersState, profile: UsersStateProfile) {
  state.profile = profile;
}

export function mutationUsersSetProfilePhotoStatus(
  state: UsersState,
  { photoId, photoStatus }: MutationUsersSetProfilePhotoStatus,
) {
  const list = state.profile?.photosList;
  if (list) {
    const photo = list.find((photo) => photo.id === photoId);
    if (photo) {
      photo.photoStatus = photoStatus;
    }
  }
}

export function mutationUsersSetAccount(state: UsersState, account: UsersStateAccount) {
  state.account = account;
}

export function mutationUsersSetExtraInfo(state: UsersState, extraInfo: UsersStateExtraInfo) {
  state.extraInfo = extraInfo;
}

export function mutationUsersProfileDeletePhotos(state: UsersState, photoIds: string[]) {
  if (state.profile) {
    state.profile.photosList = state.profile.photosList.filter(
      (photo) => !photoIds.includes(photo.id),
    );
  }
}

export function mutationUsersProfileUploadPhoto(state: UsersState, photoId: string) {
  const list = state.profile?.photosList;
  list?.push({
    id: photoId,
    photoStatus: PhotoStatus.PHOTO_STATUS_MODERATION,
    declineReason: '',
    tagsList: [],
  });
}

export function mutationUsersProfileUpdateAvatar(state: UsersState, photoId: string) {
  const list = state.profile?.photosList;
  if (list) {
    const index = list.findIndex((photo) => photo.id === photoId);
    if (index !== -1) {
      list.unshift(...list.splice(index, 1));
    }
  }
}

export function mutationUsersSetProfileField(
  state: UsersState,
  fields: Partial<UsersStateProfile>,
) {
  Object.keys(fields).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.profile[key] = fields[key];
  });
}

export function mutationUsersSetAccountField(
  state: UsersState,
  fields: Partial<UsersStateAccount>,
) {
  Object.keys(fields).forEach((key) => {
    if (state.account) {
      // @ts-expect-error: any
      state.account[key] = fields[key];
    }
  });
}

export function mutationUsersSetSettingsField(
  state: UsersState,
  fields: Partial<Settings.AsObject>,
) {
  Object.keys(fields).forEach((key) => {
    if (state.account) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.account.settings[key] = fields[key];
    }
  });
}

export function mutationUsersSetSubscription(
  state: UsersState,
  subscription: UsersStateSubscription,
) {
  state.subscription = subscription;
}

export function mutationUsersDeactivateSubscription(state: UsersState) {
  if (state.subscription) {
    const expiresTime = state.subscription.state.expiresTime;
    const expiresDuration = state.subscription.state.expiresDuration;
    state.subscription.state = {
      expiresTime,
      expiresDuration,
      type: 'inactive',
    };
  }
}

export function mutationUsersAddLogItems(state: UsersState, logs: UsersStateLogItem[]) {
  state.logs.push(...logs);
}

export function mutationUsersSetLogSearchAfter(state: UsersState, searchLogAfter: string) {
  state.searchLogAfter = searchLogAfter;
}

export function mutationUsersClearLogItems(state: UsersState) {
  state.logs = [];
}
