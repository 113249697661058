import {
  GetInfoRequest,
  GetInfoResponse,
  BatchGetRequest,
  BatchGetResponse,
  ListResponse,
  ListRequest,
  UpdateResponse,
  UpdateRequest,
  DeactivateResponse,
  DeactivateRequest,
  InviteResponse,
  InviteRequest,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_api_pb';
import { TeamMemberAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/Team_member_apiServiceClientPb';
import { TeamMemberFields } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const teamMemberAPIClient = new TeamMemberAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([teamMemberAPIClient]);

export function getInfo(): Promise<GetInfoResponse> {
  const request = new GetInfoRequest();
  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.getInfo, request);
}

export function batchGet(ids: string[]): Promise<BatchGetResponse> {
  const request = new BatchGetRequest();
  request.setTeamMemberIdsList(ids);

  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.batchGet, request);
}

export function list(requestArg: ListRequest.AsObject): Promise<ListResponse> {
  const pagination = new CursorPaginationRequest();
  pagination.setLimit(requestArg.pagination?.limit ?? 0);
  pagination.setAfterPosition(requestArg.pagination?.afterPosition ?? '');

  const request = new ListRequest();
  request.setRolesList(requestArg.rolesList);
  request.setFilter(requestArg.filter);
  request.setPagination(pagination);

  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.list, request);
}

type UpdateArg = {
  teamMemberId: string;
  fieldList: Partial<TeamMemberFields.AsObject>;
};
export function update(arg: UpdateArg): Promise<UpdateResponse> {
  const request = new UpdateRequest();
  request.setTeamMemberId(arg.teamMemberId);

  if (arg.fieldList.fullName) {
    const field = new TeamMemberFields();
    field.setFullName(arg.fieldList.fullName);
    request.addFields(field);
  }

  if (arg.fieldList.role) {
    const field = new TeamMemberFields();
    field.setRole(arg.fieldList.role);
    request.addFields(field);
  }

  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.update, request);
}

export function deactivate(teamMemberId: string): Promise<DeactivateResponse> {
  const request = new DeactivateRequest();
  request.setTeamMemberId(teamMemberId);

  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.deactivate, request);
}

export function invite(requestArg: InviteRequest.AsObject): Promise<InviteResponse> {
  const request = new InviteRequest();
  request.setRole(requestArg.role);
  request.setEmail(requestArg.email);
  request.setFullName(requestArg.fullName);

  return makeRpcPromisable(teamMemberAPIClient, teamMemberAPIClient.invite, request);
}

export const teamMemberApi = {
  getInfo,
  batchGet,
  list,
  update,
  deactivate,
  invite,
};
