import {
  AccountField,
  SettingsField,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/account_pb';
import { BillingField } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/billing_pb';
import { ProfileField } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/profile_pb';
import { AccountStatus } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';
import { ActionContext } from 'vuex';

import {
  AUDIT_LOG_LIMIT,
  BILLING_HISTORY_LIMIT,
  getAccountFrom,
  getBillingFrom,
  getBillingHistoryFrom,
  getExtraInfoFrom,
  getLogItemsFrom,
  getProfileFrom,
  getSubscriptionFrom,
  getUsersFrom,
  USERS_LIST_LIMIT,
} from '@/pages/users/store/helpers/users-helper';
import {
  UsersState,
  UsersStateAccount,
  UsersStateBilling,
  UsersStateBillingHistoryItem,
  UsersStateExtraInfo,
  UsersStateLogItem,
  UsersStateProfile,
  UsersStateSearchCriteria,
  UsersStateSubscription,
  UsersStateUser,
} from '@/pages/users/store/users-state';
import {
  deletePhoto,
  getAccount,
  getAuditLog,
  getBilling,
  getBillingHistory,
  getExtraInfo,
  getOneTimeHash,
  getProfile,
  getSubscriptionStatus,
  searchUser,
  sendEmailRecovery,
  setAccount,
  setAccountStatus,
  setBilling,
  setProfile,
  setSettings,
  unsubscribePremium,
  updateAvatar,
  uploadPhoto,
} from '@/services/api/grpc/users/users-api';
import { commit, ActionFunction } from '@/store/store-helper';

type ActionUsersSetBillingPayload = {
  userId: string;
  reasonContent: string;
  fields: BillingField.AsObject;
};

type ActionUsersSetProfilePayload = {
  userId: string;
  reasonContent: string;
  fields: ProfileField[];
};

type ActionUsersUnsubscribePremiumPayload = {
  userId: string;
  reasonContent: string;
};

type ActionUsersSetAccountPayload = {
  userId: string;
  reasonContent: string;
  fields: AccountField[];
};

type ActionUsersSetSettingsPayload = {
  userId: string;
  reasonContent: string;
  fields: SettingsField[];
};

type ActionUsersSetStatusPayload = {
  userId: string;
  reasonContent: string;
  reasonDescription?: string;
  categoryList: string[];
  status: AccountStatus;
};

type ActionUsersProfileDeletePhotosPayload = {
  userId: string;
  reasonContent: string;
  photoIds: string[];
};

type ActionUsersSendEmailRecoveryPayload = { userId: string; reasonContent: string };

type ActionUsersGetProfileReturn = {
  profile: UsersStateProfile;
};

type ActionUsersUpdateAvatarPayload = {
  userId: string;
  photoId: string;
};

type ActionUsersProfileUploadPhotoPayload = {
  userId: string;
  photoId: string;
};

declare module '@/store/store-helper' {
  interface Action {
    actionUsersGetUsers: ActionFunction<UsersStateSearchCriteria | undefined, UsersStateUser[]>;
    actionUsersGetBilling: ActionFunction<string, UsersStateBilling>;
    actionUsersSetBilling: ActionFunction<ActionUsersSetBillingPayload, void>;
    actionUsersGetBillingHistory: ActionFunction<string, UsersStateBillingHistoryItem[]>;
    actionUsersGetProfile: ActionFunction<string, ActionUsersGetProfileReturn>;
    actionUsersGetAccount: ActionFunction<string, UsersStateAccount>;
    actionUsersGetExtraInfo: ActionFunction<string, UsersStateExtraInfo>;
    actionUsersProfileDeletePhotos: ActionFunction<ActionUsersProfileDeletePhotosPayload, void>;
    actionUsersSetProfile: ActionFunction<ActionUsersSetProfilePayload, void>;
    actionUsersSetAccount: ActionFunction<ActionUsersSetAccountPayload, void>;
    actionUsersSetSettings: ActionFunction<ActionUsersSetSettingsPayload, void>;
    actionUsersSetStatus: ActionFunction<ActionUsersSetStatusPayload, void>;
    actionUsersSendEmailRecovery: ActionFunction<ActionUsersSendEmailRecoveryPayload, void>;
    actionUsersUnsubscribePremium: ActionFunction<ActionUsersUnsubscribePremiumPayload, void>;
    actionUsersGetSubscription: ActionFunction<string, UsersStateSubscription>;
    actionUsersGetLogItems: ActionFunction<string, UsersStateLogItem[]>;
    actionUsersProfileUpdateAvatar: ActionFunction<ActionUsersUpdateAvatarPayload, void>;
    actionUsersProfileUploadPhoto: ActionFunction<ActionUsersProfileUploadPhotoPayload, void>;
    actionUsersGetAccountOneTimeHash: ActionFunction<string, string>;
  }
}

export async function actionUsersGetUsers(
  { state }: ActionContext<UsersState, unknown>,
  criteria?: UsersStateSearchCriteria,
): Promise<UsersStateUser[]> {
  // If it's new search ( there are no users yet ) we won't use `searchAfter`.
  const searchAfter = state.users.length > 0 ? state.searchUsersAfter : '';

  const response = await searchUser({
    criteria,
    limit: USERS_LIST_LIMIT,
    searchAfter,
  });

  const users = getUsersFrom(response);

  commit('mutationUsersAddUsers', users);
  commit('mutationUsersSetUsersSearchAfter', response.getSearchAfter());

  return users;
}

export async function actionUsersGetBilling(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateBilling> {
  const response = await getBilling(userId);
  const billing = getBillingFrom(response);

  commit('mutationUsersSetBilling', billing);

  return billing;
}

export async function actionUsersSetBilling(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent, fields }: ActionUsersSetBillingPayload,
): Promise<void> {
  await setBilling(userId, reasonContent, fields);
}

export async function actionUsersGetBillingHistory(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateBillingHistoryItem[]> {
  // If it's new search ( there are no history yet ) we won't use `searchAfter`.
  const searchAfter = state.billingHistory.length > 0 ? state.searchBillingHistoryAfter : '';

  const response = await getBillingHistory({
    userId,
    limit: BILLING_HISTORY_LIMIT,
    searchAfter,
  });

  const history = getBillingHistoryFrom(response);

  commit('mutationUsersAddBillingHistoryItems', history);
  commit('mutationUsersSetBillingHistorySearchAfter', response.getSearchAfter());

  return history;
}

export async function actionUsersGetProfile(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<ActionUsersGetProfileReturn> {
  const response = await getProfile(userId);

  const profile = getProfileFrom(response);

  commit('mutationUsersSetProfile', profile);

  return {
    profile,
  };
}

export async function actionUsersGetAccount(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateAccount> {
  const response = await getAccount(userId);

  const account = getAccountFrom(response);

  commit('mutationUsersSetAccount', account);

  return account;
}

export async function actionUsersProfileDeletePhotos(
  { state }: ActionContext<UsersState, unknown>,
  { photoIds, userId, reasonContent }: ActionUsersProfileDeletePhotosPayload,
): Promise<void> {
  await deletePhoto(userId, reasonContent, photoIds);

  commit('mutationUsersProfileDeletePhotos', photoIds);
}

export async function actionUsersSetProfile(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent, fields }: ActionUsersSetProfilePayload,
): Promise<void> {
  await setProfile(userId, reasonContent, fields);
}

export async function actionUsersSetAccount(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent, fields }: ActionUsersSetAccountPayload,
): Promise<void> {
  await setAccount(userId, reasonContent, fields);
}

export async function actionUsersSendEmailRecovery(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent }: ActionUsersSendEmailRecoveryPayload,
): Promise<void> {
  await sendEmailRecovery(userId, reasonContent);
}

export async function actionUsersSetSettings(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent, fields }: ActionUsersSetSettingsPayload,
): Promise<void> {
  await setSettings(userId, reasonContent, fields);
}

export async function actionUsersSetStatus(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent, status, reasonDescription, categoryList }: ActionUsersSetStatusPayload,
): Promise<void> {
  await setAccountStatus(userId, status, {
    content: reasonContent,
    description: reasonDescription ?? '',
    categoryList,
  });
  commit('mutationUsersSetAccountField', {
    status,
    reason: {
      content: reasonContent,
      description: reasonDescription ?? '',
      categoryList,
    },
  });
}

export async function actionUsersGetSubscription(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateSubscription> {
  const response = await getSubscriptionStatus(userId);
  const subscription = getSubscriptionFrom(response);

  commit('mutationUsersSetSubscription', subscription);

  return subscription;
}

export async function actionUsersUnsubscribePremium(
  { state }: ActionContext<UsersState, unknown>,
  { userId, reasonContent }: ActionUsersUnsubscribePremiumPayload,
): Promise<void> {
  await unsubscribePremium(userId, reasonContent);
  commit('mutationUsersDeactivateSubscription');
}

export async function actionUsersGetLogItems(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateLogItem[]> {
  const searchAfter = state.logs.length > 0 ? state.searchLogAfter : '';

  const response = await getAuditLog({
    userId,
    limit: AUDIT_LOG_LIMIT,
    searchAfter,
  });

  const items = getLogItemsFrom(response);

  commit('mutationUsersAddLogItems', items);
  commit('mutationUsersSetLogSearchAfter', response.getSearchAfter());

  return items;
}

export async function actionUsersProfileUpdateAvatar(
  { state }: ActionContext<UsersState, unknown>,
  { userId, photoId }: ActionUsersUpdateAvatarPayload,
): Promise<void> {
  await updateAvatar({ userId, photoId });

  commit('mutationUsersProfileUpdateAvatar', photoId);
}

export async function actionUsersProfileUploadPhoto(
  { state }: ActionContext<UsersState, unknown>,
  { userId, photoId }: ActionUsersProfileUploadPhotoPayload,
): Promise<void> {
  await uploadPhoto({ userId, photoId });

  commit('mutationUsersProfileUploadPhoto', photoId);
}

export async function actionUsersGetAccountOneTimeHash(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<string> {
  const response = await getOneTimeHash({
    userId,
  });

  return response.getHash();
}

export async function actionUsersGetExtraInfo(
  { state }: ActionContext<UsersState, unknown>,
  userId: string,
): Promise<UsersStateExtraInfo> {
  const response = await getExtraInfo(userId);

  const extraInfo = getExtraInfoFrom(response);

  commit('mutationUsersSetExtraInfo', extraInfo);

  return extraInfo;
}
