type SmartlookAction = 'init' | 'identify' | 'record';

declare global {
  interface Window {
    smartlook: (
      action: SmartlookAction,
      identity: string | Record<string, unknown>, // it might be `userId` or `projectId` depends on `action` parameter
      customParams?: Record<string, unknown>,
    ) => void | jest.Mock;
  }
}

const PROJECT_KEY = 'deffde93d679fa3b7c12d47ba0455b3137c1a969';

// https://web.developer.smartlook.com/docs
function loadScript(): void {
  window.smartlook ||
    (function (d) {
      const o = (window.smartlook = function () {
          // @ts-expect-error: vendor script
          // eslint-disable-next-line
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName('head')[0];
      const c = d.createElement('script');
      // @ts-expect-error: vendor script
      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://web-sdk.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);

  window.smartlook('init', PROJECT_KEY, { region: 'eu' });
  window.smartlook('record', { forms: true, numbers: true });
}

type UserIdentity = {
  userId: string;
  email: string;
};

// https://web.developer.smartlook.com/docs/identify-visitor
export function identifyUser({ userId, email }: UserIdentity): void {
  window.smartlook('identify', userId, {
    email,
  });
}

let isInitialized = false;
export async function runSmartLook(identity: UserIdentity): Promise<void> {
  if (isInitialized || +(process.env.VUE_APP_IS_SMARTLOOK_ENABLED ?? 0) === 0) {
    return;
  }

  loadScript();
  identifyUser(identity);

  isInitialized = true;
}
