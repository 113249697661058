type UseUiEventArg = {
  handleEnterKeyDown?(event: KeyboardEvent): void;
  handleEscapeKeyDown?(event: KeyboardEvent): void;
};

export function useUiEvent(arg: UseUiEventArg) {
  function handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        arg.handleEnterKeyDown?.(event);
        break;
      case 'Escape':
        arg.handleEscapeKeyDown?.(event);
        break;
    }
  }

  function init() {
    window.addEventListener('keydown', handleKeyDown);
  }

  function destroy() {
    window.removeEventListener('keydown', handleKeyDown);
  }

  return {
    init,
    destroy,
  };
}
