export type ErrorStateMessageItemType = 'relogin';

export type ErrorStateMessageItem = {
  isVisible: boolean;
  type: ErrorStateMessageItemType;
};

export type ErrorState = {
  errors: ErrorStateMessageItem[];
};

export const state: ErrorState = {
  errors: [],
};
