import { Note } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_pb';

export type NotesState = {
  notes: Note.AsObject[];
  profileNotes: Note.AsObject[];
  count: number;
  totalPages: number;
  limit: number;
};

export const state: NotesState = {
  notes: [],
  profileNotes: [],
  count: 0,
  totalPages: 0,
  limit: 10,
};
