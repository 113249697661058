import { Status } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';

import {
  hideSessionBreakModal,
  showSessionBreakModal,
} from '@/components/session/session-break-modal/helpers/session-break-modal/session-break-modal';
import { dispatch, getState } from '@/store/store-helper';

export function isBreakActive(): boolean {
  const session = getState().chat.session;

  if (session) {
    return session.status === Status.STATUS_ON_BREAK;
  }

  return false;
}

export async function startBreak() {
  const session = await dispatch('actionChatGetSession');

  if (session) {
    await dispatch('actionChatSetSessionStatus', Status.STATUS_ON_BREAK);

    showSessionBreakModal({
      session,
    });
  }
}

export async function stopBreak() {
  await dispatch('actionChatSetSessionStatus', Status.STATUS_ACTIVE);

  hideSessionBreakModal();
}
