import * as gtag from '@project-gd-x/shared-web/src/helpers/gtag/gtag';

function isGtagEnabled() {
  return process.env.VUE_APP_IS_GTAG_ENABLED === '1';
}

export function loadGtag() {
  if (isGtagEnabled()) {
    gtag.loadGtag({
      tagId: 'G-ETV6ZXB8FC',
    });
  }
}

const EVENT_PREFIX = 'bo_';

type PushGtagEventOptions = {
  userId?: string;
};

export function pushGtagEvent(
  eventName: string,
  eventParamList: Record<string, unknown> = {},
  options: PushGtagEventOptions = {},
) {
  const eventParamModifiedList = Object.assign({}, eventParamList);

  if (options.userId) {
    eventParamModifiedList.user_id = options.userId;
  }

  if (isGtagEnabled()) {
    gtag.pushGtagEvent(EVENT_PREFIX + eventName, eventParamModifiedList);
  } else {
    console.log('gtag.pushGtagEvent', EVENT_PREFIX + eventName, eventParamModifiedList);
  }
}
