import { Feature } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/team/v1/team_member_pb';
import { ActionContext } from 'vuex';

import { getMemberFrom, getMembersMapFrom } from '@/pages/member/store/helpers/member-helper';
import { MemberState, MemberStateMember } from '@/pages/member/store/member-state';
import { batchGet, getInfo } from '@/services/api/grpc/team-member/team-member-api';
import { commit, ActionFunction } from '@/store/store-helper';

type ActionSettingsGetAccountReturn = {
  features: Feature[];
  member: MemberStateMember;
};

declare module '@/store/store-helper' {
  interface Action {
    actionMemberGetInfo: ActionFunction<never, ActionSettingsGetAccountReturn>;
    actionMemberLoadBatch: ActionFunction<string[], void>;
  }
}

export async function actionMemberGetInfo(): Promise<ActionSettingsGetAccountReturn> {
  const response = await getInfo();
  const obj = response.toObject();

  const member = getMemberFrom(obj.memberInfo);
  const features = obj.featuresList;

  commit('mutationMemberSetMember', member);
  commit('mutationMemberSetFeatures', features);

  return {
    member,
    features,
  };
}

export async function actionMemberLoadBatch(
  { state }: ActionContext<MemberState, unknown>,
  ids: string[],
): Promise<void> {
  const response = await batchGet(ids);

  commit('mutationMemberSetMembers', getMembersMapFrom(response.getTeamMembersMap()));
}
