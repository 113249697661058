import {
  Dialog,
  DialogIdentity,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_pb';
import {
  ResolveReportRequest,
  ResolveReportResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_abuse_api_pb';
import { ActionContext } from 'vuex';

import { MESSAGES_LIMIT } from '@/pages/chat/store/helpers/chat-helper';
import {
  getDialogFrom,
  getMessagesFrom,
  getReportsListFrom,
} from '@/pages/report-abuse/store/helpers/report-abuse-helper';
import {
  ReportAbuseState,
  ReportAbuseStateAbuseReport,
  ReportAbuseStateDialog,
  ReportAbuseStateDialogMessage,
} from '@/pages/report-abuse/store/report-abuse-state';
import { getDialog, listDialogMessages } from '@/services/api/grpc/dialog-api/dialog-api';
import {
  listReports,
  ListReportsArguments,
  resolveReport,
  ResolveReportArguments,
} from '@/services/api/grpc/reports-api/reports-api';
import { store } from '@/store';
import { ActionFunction, commit } from '@/store/store-helper';

declare module '@/store/store-helper' {
  interface Action {
    actionReportListReports: ActionFunction<ListReportsArguments, ReportAbuseStateAbuseReport[]>;
    actionReportResolveReportAbuse: ActionFunction<
      ResolveReportRequest.AsObject,
      ResolveReportResponse.AsObject
    >;
    actionReportListDialogMessages: ActionFunction<
      DialogIdentity.AsObject,
      ReportAbuseStateDialogMessage[]
    >;
    actionReportGetDialog: ActionFunction<DialogIdentity.AsObject, ReportAbuseStateDialog>;
  }
}

export async function actionReportListReports(
  { state }: ActionContext<ReportAbuseState, unknown>,
  payload: ListReportsArguments,
): Promise<ReportAbuseStateAbuseReport[]> {
  const response = await listReports(payload);
  return getReportsListFrom(response);
}

export async function actionReportResolveReportAbuse(
  { state }: ActionContext<ReportAbuseState, unknown>,
  arg: ResolveReportRequest.AsObject,
): Promise<ResolveReportResponse.AsObject> {
  const response = await resolveReport(arg);
  const resolvedBy = store.state.member.member;

  commit('mutationReportResolveReportAbuse', { ...arg, resolvedBy });

  return response;
}

export async function actionReportListDialogMessages(
  { state }: ActionContext<ReportAbuseState, unknown>,
  { userId, dialogId }: DialogIdentity.AsObject,
): Promise<ReportAbuseStateDialogMessage[]> {
  const response = await listDialogMessages({
    afterPosition: state.messagesAfter,
    limit: MESSAGES_LIMIT,
    dialogId,
    userId,
  });
  const messages = getMessagesFrom(response);

  commit('mutationReportAddMessages', {
    dialogId,
    messages,
  });
  commit('mutationReportSetMessagesAfter', response.getCursor()?.getNext() ?? '');

  return messages;
}

export async function actionReportGetDialog(
  { state }: ActionContext<ReportAbuseState, unknown>,
  identity: DialogIdentity.AsObject,
): Promise<ReportAbuseStateDialog> {
  const response = await getDialog(identity);

  const dialog = getDialogFrom(response.getDialog() as Dialog);

  commit('mutationReportSetMessagesHeaderDialog', dialog);
  commit('mutationReportSetDialog', dialog);

  return dialog;
}
