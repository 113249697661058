import {
  AccountStatus,
  ProfilePreview,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';

export function getProfilePreviewFrom(
  profile: ProfilePreview | undefined,
): Required<ProfilePreview.AsObject> {
  return profile?.toObject() as Required<ProfilePreview.AsObject>;
}

export function defaultProfilePreview(): ProfilePreview.AsObject {
  return {
    id: '',
    email: '',
    avatar: {
      fileId: '',
      isDummy: false,
      tagsList: [],
    },
    age: 0,
    isLocked: false,
    isOnline: false,
    locationName: '',
    name: '',
    status: AccountStatus.ACCOUNT_STATUS_INVALID,
    timezone: {
      timezone: '',
      offsetMinutes: 0,
    },
    registrationIp: '',
    registrationCountryCode: '',
  };
}
