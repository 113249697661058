import { I18n } from '@/services/i18n';

type Message = {
  currentBreak: string;
  totalBreak: string;
  breakOverdue: string;
  backToWork: string;
  stopSession: string;
};

const messages: I18n<Message> = {
  en: {
    currentBreak: 'current break',
    totalBreak: 'Total break left',
    breakOverdue: 'break overdue',
    backToWork: 'Back to work',
    stopSession: 'End session',
  },
};

export { messages };
