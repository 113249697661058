import { CaptureContext, SeverityLevel, User } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import { Vue } from '@sentry/vue/types/types';

import { isLocalhost } from '@/helpers/location/location';

// Errors percent to track ( 1 means 100% )
const SAMPLE_RATE = 1;

export function setSentryUser(user: Partial<User>): void {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
}

let isSentryInitialized = false;
export function initSentry(app: Vue): void {
  // Enable Sentry only on production.
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!isSentryInitialized) {
    isSentryInitialized = true;
    Sentry.init({
      app,
      attachProps: true,
      logErrors: true,
      dsn: 'https://37795f09c5106fe1a535c3b7e3f58f1f@o446664.ingest.sentry.io/4505718960357376',
      sampleRate: SAMPLE_RATE,
      debug: false,
      release: process.env.VUE_APP_BUILD_VERSION,
      // integrations: [
      //   new Sentry.BrowserTracing({
      //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //     // tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
      //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //   }),
      // ],
      // tracesSampleRate: SAMPLE_RATE,

      // All patterns are form here https://gist.github.com/impressiver/5092952
      // More here https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        'adsbygoogle',
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Script error.',
        // 'Non-Error promise rejection captured with keys: code, message, metadata',
        // 'Non-Error exception captured with keys: code, message, metadata',
        // Simple vue-router warning, it's okay to be fired during navigation redirects
        'Navigation cancelled from',
        'Such email is already registered',

        // Error response from backend, is not frontend error case
        // 'keys: code, message, metadata',
        'RpcError',

        // It's an issue with IAP, so mute this error
        'Unknown Content-type received',
      ],
      denyUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
}

export function sentryCaptureMessage({
  message,
  captureContext,
}: {
  message: string;
  captureContext?: CaptureContext | SeverityLevel;
}): void {
  if (process.env.NODE_ENV !== 'test') {
    if (isLocalhost()) {
      console.warn(message, captureContext);
    } else {
      if (isSentryInitialized) {
        Sentry.captureMessage(message, captureContext);
      }
    }
  }
}
