import grpcWeb, { StatusCode } from 'grpc-web';

import { commit } from '@/store/store-helper';

export const GRPC_ENDPOINT = process.env.VUE_APP_GRPC_URL as string;

export function makeRpcPromisable<T, U>(
  service: unknown,
  method: (request: T, metadata: grpcWeb.Metadata | null) => Promise<U>,
  request: T,
): Promise<U> {
  const response = method.call(service, request, null);

  response.catch((error: grpcWeb.RpcError) => {
    if (error.code === StatusCode.UNKNOWN && error.message === 'Unknown Content-type received.') {
      commit('mutationErrorAddMessageItem', 'relogin');
    }
    return error;
  });

  return response;
}
