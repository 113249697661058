import {
  SaveDialogNoteRequest,
  SaveProfileNoteRequest,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_api_pb';
import { Note } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/notes/v1/notes_pb';

import {
  getNote,
  getProfileNote,
  saveNote as saveNoteApi,
  saveProfileNote as saveProfileNoteApi,
} from '@/services/api/grpc/notes-api/notes-api';

export async function saveNote(
  params: SaveDialogNoteRequest.AsObject,
): Promise<Note.AsObject | void> {
  try {
    const id = await saveNoteApi(params);
    const response = await getNote(id.getId());
    return response.getNote()?.toObject() as Note.AsObject;
  } catch (error) {
    return console.warn(error);
  }
}

export async function saveProfileNote(
  params: SaveProfileNoteRequest.AsObject,
): Promise<Note.AsObject | void> {
  try {
    const id = await saveProfileNoteApi(params);
    const response = await getProfileNote(id.getId());
    return response.getNote()?.toObject() as Note.AsObject;
  } catch (error) {
    return console.warn(error);
  }
}
