import {
  BoostUsersDialogsRequest,
  BoostUsersDialogsResponse,
  CompleteDialogRequest,
  CompleteDialogResponse,
  DeleteMessagesRequest,
  DeleteMessagesResponse,
  EndSessionRequest,
  EndSessionResponse,
  GetDialogRequest,
  GetDialogResponse,
  GetSessionRequest,
  GetSessionResponse,
  GetUserActivityRequest,
  GetUserActivityResponse,
  GetUsersDialogsBoostRequest,
  GetUsersDialogsBoostResponse,
  KeepAliveSessionRequest,
  KeepAliveSessionResponse,
  ListDialogMacrosRequest,
  ListDialogMacrosResponse,
  ListDialogMessagesRequest,
  ListDialogMessagesResponse,
  ListDialogsRequest,
  ListDialogsResponse,
  ListMessagesOperatorRequest,
  ListMessagesOperatorResponse,
  ListQueueDialogsRequest,
  ListQueueDialogsResponse,
  RemoveUsersDialogsBoostRequest,
  RemoveUsersDialogsBoostResponse,
  ReportSuspiciousContentRequest,
  ReportUserAbuseRequest,
  ReportUserAbuseResponse,
  SendMessageRequest,
  SendMessageResponse,
  SetSessionStatusRequest,
  SetSessionStatusResponse,
  SkipDialogRequest,
  SkipDialogResponse,
  StartSessionRequest,
  StartSessionResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/chatters_api_pb';
import { ChattersAPIClient } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/Chatters_apiServiceClientPb';
import {
  DialogIdentity,
  DialogPriority,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/identities_pb';
import {
  Channel,
  Status,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/chatters/v1/session_pb';
import { CursorPaginationRequest } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/pagination_pb';
import { Duration } from 'google-protobuf/google/protobuf/duration_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import { enableDevTools } from '@/helpers/grpc-web-extention';
import { ChatStateDialogMessageContent } from '@/pages/chat/store/chat-state';
import { DIALOGS_MACROS_LIMIT } from '@/pages/chat/store/helpers/chat-helper';
import { ProfileStateAbuseReason } from '@/pages/report-abuse/store/report-abuse-state';
import { GRPC_ENDPOINT, makeRpcPromisable } from '@/services/api/grpc';

const chattersAPIClient = new ChattersAPIClient(GRPC_ENDPOINT, null, null);

enableDevTools([chattersAPIClient]);

export type ListDialogsArgumentUser =
  | { type: 'user'; userId: string }
  | { type: 'chatter'; chatterId: string };

export type ListDialogsArgument = {
  user?: ListDialogsArgumentUser;
  filter: string;
  limit: number;
  afterPosition: string;
  priority?: DialogPriority;
};

export type ReportUserAbuseArgument = {
  userId: string;
  chatterId: string;
  reason: ProfileStateAbuseReason;
  description: string;
};

export function reportUserAbuse({
  userId,
  chatterId,
  description,
  reason,
}: ReportUserAbuseArgument): Promise<ReportUserAbuseResponse> {
  const request = new ReportUserAbuseRequest();
  request.setUserId(userId);
  request.setChatterId(chatterId);
  request.setReason(reason);
  request.setDescription(description);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.reportUserAbuse, request);
}

export function deleteMessages(
  chatterId: string,
  dialogId: string,
  messageIds: string[],
): Promise<DeleteMessagesResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new DeleteMessagesRequest();
  request.setIdentity(identity);
  request.setMessageIdsList(messageIds);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.deleteMessages, request);
}

export function reportSuspiciousContent(
  chatterId: string,
  dialogId: string,
  messageId: string,
): Promise<DeleteMessagesResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new ReportSuspiciousContentRequest();
  request.setIdentity(identity);
  request.setMessageId(messageId);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.reportSuspiciousContent, request);
}

export function listDialogs({
  limit,
  afterPosition,
  filter,
  priority,
}: ListDialogsArgument): Promise<ListDialogsResponse> {
  const cursor = new CursorPaginationRequest();
  cursor.setLimit(limit);
  cursor.setAfterPosition(afterPosition);

  const request = new ListDialogsRequest();
  request.setCursor(cursor);

  if (priority) {
    request.setPriority(priority);
  }

  if (filter) {
    const filterValue = new StringValue();
    filterValue.setValue(filter);
    request.setFilter(filterValue);
  }

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.listDialogs, request);
}

export function listDialogMessages({
  cursor: { afterPosition, limit },
  identity: { dialogId, chatterId },
  displayDeletedMessages,
}: Required<ListDialogMessagesRequest.AsObject>): Promise<ListDialogMessagesResponse> {
  const cursor = new CursorPaginationRequest();
  cursor.setLimit(limit);
  cursor.setAfterPosition(afterPosition);

  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new ListDialogMessagesRequest();
  request.setCursor(cursor);
  request.setIdentity(identity);
  request.setDisplayDeletedMessages(displayDeletedMessages);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.listDialogMessages, request);
}

export function getDialog({
  chatterId,
  dialogId,
}: DialogIdentity.AsObject): Promise<GetDialogResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new GetDialogRequest();
  request.setIdentity(identity);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.getDialog, request);
}

export function sendMessage(
  { chatterId, dialogId }: DialogIdentity.AsObject,
  message: ChatStateDialogMessageContent,
  isMacro = false,
): Promise<SendMessageResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new SendMessageRequest();
  request.setIdentity(identity);
  request.setIsMacro(isMacro);

  switch (message.type) {
    case 'text':
      request.setText(message.text);
      break;
    case 'image':
      request.setPhotoId(message.imageId);
      break;
    case 'gift':
      {
        const gift = new SendMessageRequest.Gift();
        gift.setGiftId(message.giftId);
        gift.setText(message.giftText);
        request.setGift(gift);
      }
      break;
    default:
      throw new Error("Message can't be empty.");
  }

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.sendMessage, request);
}

export function skipDialog({
  chatterId,
  dialogId,
}: DialogIdentity.AsObject): Promise<SkipDialogResponse> {
  const identity = new DialogIdentity();
  identity.setChatterId(chatterId);
  identity.setDialogId(dialogId);

  const request = new SkipDialogRequest();
  request.setIdentity(identity);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.skipDialog, request);
}

export function startSession(channels: Channel[]): Promise<StartSessionResponse> {
  const request = new StartSessionRequest();
  request.setChannelsList(channels);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.startSession, request);
}

export function endSession(): Promise<EndSessionResponse> {
  const request = new EndSessionRequest();

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.endSession, request);
}

export function getSession(): Promise<GetSessionResponse> {
  const request = new GetSessionRequest();

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.getSession, request);
}

export function keepAliveSession(): Promise<KeepAliveSessionResponse> {
  const request = new KeepAliveSessionRequest();

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.keepAliveSession, request);
}

export function listDialogMacros({
  dialogId,
  chatterId,
}: DialogIdentity.AsObject): Promise<ListDialogMacrosResponse> {
  const request = new ListDialogMacrosRequest();

  const identity = new DialogIdentity();
  identity.setDialogId(dialogId);
  identity.setChatterId(chatterId);
  request.setIdentity(identity);

  const cursor = new CursorPaginationRequest();
  cursor.setAfterPosition('');
  cursor.setLimit(DIALOGS_MACROS_LIMIT);
  request.setCursor(cursor);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.listDialogMacros, request);
}

type FetchDialogQueueListArg = {
  limit: number;
  lastRepliedDialogIdentity: DialogIdentity.AsObject;
};
export function fetchDialogQueueList({
  limit,
  lastRepliedDialogIdentity,
}: FetchDialogQueueListArg): Promise<ListQueueDialogsResponse> {
  const request = new ListQueueDialogsRequest();
  request.setLimit(limit);

  const dialogIdentity = new DialogIdentity();
  dialogIdentity.setDialogId(lastRepliedDialogIdentity.dialogId);
  dialogIdentity.setChatterId(lastRepliedDialogIdentity.chatterId);
  request.setLastRepliedDialog(dialogIdentity);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.listQueueDialogs, request);
}

type FetchCompleteDialogArg = {
  hasReply: boolean;
  dialogIdentity: DialogIdentity.AsObject;
};
export function fetchCompleteDialog(arg: FetchCompleteDialogArg): Promise<CompleteDialogResponse> {
  const dialogIdentity = new DialogIdentity();
  dialogIdentity.setDialogId(arg.dialogIdentity.dialogId);
  dialogIdentity.setChatterId(arg.dialogIdentity.chatterId);

  const request = new CompleteDialogRequest();
  request.setIdentity(dialogIdentity);
  request.setHasReply(arg.hasReply);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.completeDialog, request);
}

export function getUserActivity(userId: string): Promise<GetUserActivityResponse> {
  const request = new GetUserActivityRequest();
  request.setUserId(userId);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.getUserActivity, request);
}

export function setSessionStatus(status: Status): Promise<SetSessionStatusResponse> {
  const request = new SetSessionStatusRequest();
  request.setNewStatus(status);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.setSessionStatus, request);
}

type BoostUsersDialogsArg = {
  userId: string;
  boostValue: number;
  boostDurationSeconds: number;
};
export function boostUsersDialogs(arg: BoostUsersDialogsArg): Promise<BoostUsersDialogsResponse> {
  const request = new BoostUsersDialogsRequest();
  request.setUserId(arg.userId);
  request.setBoostValue(arg.boostValue);

  const duration = new Duration();
  duration.setSeconds(arg.boostDurationSeconds);
  request.setBoostDuration(duration);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.boostUsersDialogs, request);
}

export function removeUsersDialogsBoost(userId: string): Promise<RemoveUsersDialogsBoostResponse> {
  const request = new RemoveUsersDialogsBoostRequest();
  request.setUserId(userId);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.removeUsersDialogsBoost, request);
}

export function getUsersDialogsBoost(userId: string): Promise<GetUsersDialogsBoostResponse> {
  const request = new GetUsersDialogsBoostRequest();
  request.setUserId(userId);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.getUsersDialogsBoost, request);
}

function listMessagesOperator(messageIdList: string[]): Promise<ListMessagesOperatorResponse> {
  const request = new ListMessagesOperatorRequest();
  request.setMessageIdList(messageIdList);

  return makeRpcPromisable(chattersAPIClient, chattersAPIClient.listMessagesOperator, request);
}

export const chattersApi = {
  listDialogs,
  listDialogMessages,
  getDialog,
  sendMessage,
  skipDialog,
  startSession,
  endSession,
  getSession,
  keepAliveSession,
  listDialogMacros,
  fetchDialogQueueList,
  fetchCompleteDialog,
  getUserActivity,
  boostUsersDialogs,
  removeUsersDialogsBoost,
  getUsersDialogsBoost,
  reportUserAbuse,
  deleteMessages,
  reportSuspiciousContent,
  listMessagesOperator,
};
