export const CACHE_PREFIX = 'gdx_backoffice_';

export function setCache(key: string, value: string) {
  localStorage.setItem(CACHE_PREFIX + key, value);
}

export function getCache(key: string): string | null {
  return localStorage.getItem(CACHE_PREFIX + key);
}

export function removeCache(key: string): void {
  localStorage.removeItem(CACHE_PREFIX + key);
}
