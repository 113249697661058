import * as actions from './payments-actions';
import * as mutations from './payments-mutations';
import { state } from './payments-state';

export const paymentsPersisted = ['payments.searchCriteria'];

export const payments = {
  state,
  mutations,
  actions,
};
